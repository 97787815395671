import { tracking as trackingUtil } from 'utils/tracking/tracking';
import { useEffect, useRef, useCallback } from 'react';
import { useInViewport } from '@xxxlgroup/hydra-ui-components';

export const useTracking = (props, type) =>
  useCallback(
    (event, data = {}) => {
      // for this to work we need to pass props containing a tracking object coming from
      // wrapping the component in track from react-tracking
      trackingUtil({ props, type }, event, data);
    },
    [props, type],
  );

export const useImpressionTracker = (
  props,
  type,
  data = null,
  { timeForImpression = 1000, intersectionPosition = 0.5, elementVisibility = 0.8 } = {},
) => {
  const tracking = useTracking(props, type);
  const hasTrackedOnce = useRef(false);

  const isIntersecting = useCallback(
    (entry) => entry.isIntersecting && entry.intersectionRatio >= intersectionPosition,
    [intersectionPosition],
  );

  const { setRef, isInViewport } = useInViewport({
    once: true,
    root: null,
    rootMargin: '0px 0px 0px 0px',
    isIntersecting,
    skip: false,
    threshold: elementVisibility,
  });

  useEffect(() => {
    let timeout;

    if (!hasTrackedOnce.current && isInViewport) {
      timeout = setTimeout(() => {
        tracking('impression', { ...data });
        hasTrackedOnce.current = true;
      }, timeForImpression);
    }

    return () => clearTimeout(timeout);
  }, [data, isInViewport, props, timeForImpression, tracking, type]);

  return { setRef, tracking };
};

const percentageSteps = [25, 50, 75, 90];

export const useVideoTracking = (props, type) => {
  const progressTracked = useRef([]);

  const track = useTracking(props, type);
  const addVideoTracking = useCallback(
    (hashCode, filename, trackingObj = {}) =>
      (event) =>
        track(event, { id: hashCode, filename, ...trackingObj }),
    [track],
  );

  const trackVideoPlayProgress = useCallback(
    (hashCode, filename) => (progress) => {
      percentageSteps.forEach((percentageEdge) => {
        if (progress > percentageEdge && !progressTracked.current.includes(percentageEdge)) {
          progressTracked.current.push(percentageEdge);
          addVideoTracking(hashCode, filename, { eventAction: `${percentageEdge}%` })(null);
        }
      });
    },
    [addVideoTracking],
  );

  const trackingAttributes = useCallback(
    (hashCode, filename) => ({
      onPause: addVideoTracking(hashCode, filename),
      onPlay: addVideoTracking(hashCode, filename),
      onEnded: addVideoTracking(hashCode, filename),
      onResume: addVideoTracking(hashCode, filename, { eventAction: 'resumed' }),
      onPlayProgessChanged: trackVideoPlayProgress(hashCode, filename),
    }),
    [addVideoTracking, trackVideoPlayProgress],
  );

  return { trackingAttributes, addVideoTracking, trackVideoPlayProgress };
};
