import { useMemo } from 'react';
import useMessage from 'components/Message/useMessage';
import { Product } from 'graphql-types/generated/types-generated';

/**
 * Returning all the necessary props for package based products like floors and
 * make them ready to pass to PricePerUnit Hydra component.
 */
export const usePackageUnitProps = (priceData: Product['priceData']) => {
  const { currentPrice, sellingAmount: unitAmount, sellingUnit: unit } = priceData ?? {};
  const productPrice = currentPrice?.value;
  const [packageNameMessage] = useMessage(['wxs.package.name']);

  const packageData = useMemo(
    () => ({
      packageData: {
        name: packageNameMessage,
        productPrice: productPrice ?? undefined,
        unitAmount: unitAmount ?? undefined,
      },
      unit: unit ?? '',
    }),
    [packageNameMessage, productPrice, unit, unitAmount],
  );

  if (!productPrice || !unit || (Number.isInteger(unitAmount) && (unitAmount as number) < 2)) {
    return null;
  }

  return packageData;
};

/**
 * Returning all the necessary props for products with single units (like "l", "ml", etc. ) and
 * make them ready to pass to PricePerUnit Hydra component.
 * This hook is not handling the unit props for package based products like floors.
 */
export const useSingleUnitProps = (priceData: Product['priceData']) => {
  const { sellingUnit: unit, pricePerUnit, sellingAmount: unitAmount } = priceData ?? {};
  const unitPrice = pricePerUnit?.value;

  const unitData = useMemo(
    () => ({
      unit: unit || '',
      unitPrice: unitPrice || undefined,
    }),
    [unit, unitPrice],
  );

  if (!unitPrice || unitAmount?.toString() === '1') {
    return null;
  }

  return unitData;
};

/**
 * Returning all the unit related props, which should be passed to the PricePerUnit Hydra component
 */
export const useUnitProps = (priceData: Product['priceData']) => {
  const { productUnitDiffersToPriceUnit } = priceData ?? {};
  const unitPropsSingle = useSingleUnitProps(priceData);
  const unitPropsPackage = usePackageUnitProps(priceData);

  return productUnitDiffersToPriceUnit ? unitPropsPackage : unitPropsSingle;
};

/**
 * Setting the right unit-prop for the PriceCurrent from Hydra
 */
export const getCurrentPriceUnitProp = (priceData: Product['priceData']) => {
  const { productUnitDiffersToPriceUnit, sellingUnit: unit } = priceData ?? {};

  return productUnitDiffersToPriceUnit && unit ? unit : undefined;
};
