import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { Category, QueryGetSearchResultsArgs } from 'graphql-types/generated/types-generated';
import usePageView from 'pages/CategoryView/hooks/usePageView';
import { RetailMediaPageNames } from 'hooks/useRetailMediaScript.types';
import { PageViewTypes } from './Category.provider.types';

export { PageViewTypes } from './Category.provider.types';

export type SearchMethod =
  | ''
  | 'inspo_image'
  | 'location_change' // used for search_hub, but not for webrocket
  | 'manual'
  | 'microphone'
  | 'suggested_category'
  | 'suggested_keyword'
  | 'suggested_product'
  | 'upload_picture';

type SearchType = 'text' | 'voice' | 'image';

export interface SearchTracking {
  method: SearchMethod;
  productCodes: string[];
  redirect: boolean;
  searchPass: string;
  term: string;
  totalResults: number;
}

interface CategoryProvider {
  category: Category | null;
  encodedVariables: MutableRefObject<QueryGetSearchResultsArgs | null>;
  pageView: PageViewTypes;
  searchTracking: MutableRefObject<SearchTracking>;
  setCategory: Dispatch<SetStateAction<Category | null>>;
}

export const SEARCH_TYPE: Record<SearchMethod, SearchType> = {
  '': 'text',
  inspo_image: 'image',
  location_change: 'text',
  manual: 'text',
  microphone: 'voice',
  suggested_category: 'text',
  suggested_keyword: 'text',
  suggested_product: 'text',
  upload_picture: 'image',
};

export const DEFAULT_SEARCH_TRACKING: SearchTracking = {
  method: '',
  productCodes: [],
  redirect: false,
  searchPass: '',
  term: '',
  totalResults: 0,
};

export const DEFAULT_CATEGORY_CONTEXT = {
  category: null,
  encodedVariables: { current: null },
  pageView: PageViewTypes.Others,
  searchTracking: { current: DEFAULT_SEARCH_TRACKING },
  setCategory: () => {},
};

export const CategoryContext = createContext<CategoryProvider>(DEFAULT_CATEGORY_CONTEXT);

export const getPageViewTypeFromPageView = (pageView: PageViewTypes) => {
  switch (pageView) {
    case PageViewTypes.MPLP:
      return RetailMediaPageNames.MPLP;
    case PageViewTypes.Category:
      return RetailMediaPageNames.LIST;
    default:
      return RetailMediaPageNames.SEARCH_RESULTS;
  }
};

export const useCategoryContext = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }: PropsWithChildren) => {
  const encodedVariables = useRef<QueryGetSearchResultsArgs | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const pageView = usePageView();
  const searchTracking = useRef<SearchTracking>(DEFAULT_SEARCH_TRACKING);

  const contextValue: CategoryProvider = useMemo(
    () => ({
      category,
      pageView,
      encodedVariables,
      searchTracking,
      setCategory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, pageView, searchTracking, encodedVariables?.current],
  );

  return <CategoryContext.Provider value={contextValue}>{children}</CategoryContext.Provider>;
};
