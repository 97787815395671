import PropTypes from 'prop-types';
import { Types } from '@xxxlgroup/hydra-ui-components';

/**
 * Definition of the availability of a product.
 */
const AVAILABILITY_STATUS = {
  onlineAvailable: 'ONLINEAVAILABLE',
  onlyInStore: 'ONLYINSTORE',
};

const availabilityStatus = PropTypes.oneOf(Object.values(AVAILABILITY_STATUS));

/**
 * Definition of the delivery types
 */
const DELIVERY_TYPES = {
  postalDelivery: 'POSTALDELIVERY',
  delivery: 'DELIVERY',
};

const deliveryTypes = PropTypes.oneOf(Object.values(DELIVERY_TYPES));

/**
 * Definition of an array of breadcrumbs.
 */
const breadCrumbs = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    itemCode: PropTypes.string.isRequired,
    seoUrl: PropTypes.string,
  }),
);

/**
 * Definition of a reservation / checkout cart.
 */
const cart = PropTypes.shape({
  code: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  totalItems: PropTypes.number,
  totalPrice: PropTypes.shape(),
  totalPriceWithTax: PropTypes.shape(),
});

/**
 * Definition of product characteristics
 */
const characteristicData = PropTypes.shape({
  detailAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      name: PropTypes.string,
    }),
  ),
});

/**
 * Definition of contact data.
 */
const contactData = PropTypes.shape({
  email: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  postalcode: PropTypes.string,
  selectBoxTitle: PropTypes.string,
  streetname: PropTypes.string,
  streetnumber: PropTypes.string,
  town: PropTypes.string,
});

/**
 * Definition of graphql query response object
 */
const cmsPageData = PropTypes.shape({
  getContentPage: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  variables: PropTypes.shape(),
  isInSmartEdit: PropTypes.bool,
});

/**
 * Definition of graphql query response object
 */
const cmsTemplateData = PropTypes.shape({
  getTemplatePage: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  variables: PropTypes.shape(),
  isInSmartEdit: PropTypes.bool,
});

/**
 * Definition of graphql query response object
 */
const cmsProductSpecificData = PropTypes.shape({
  getProductSpecificContent: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  variables: PropTypes.shape(),
  isInSmartEdit: PropTypes.bool,
});

/**
 * Definition of product content data
 */
const cmsProductContentData = PropTypes.shape({
  getProductContent: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
});

/**
 * Definition of data which is necessary to render an energy efficiency label
 */
const energyEfficiency = PropTypes.shape({
  classColor: PropTypes.string,
  eeClass: PropTypes.string,
  energyLabel2021Shown: PropTypes.bool,
  // cdn image data
  label: PropTypes.shape(),
});

/**
 * The commonly returned error object.
 * In some cases, more data will be returned from backend - in this case, you can pass additional
 * object members in order to customize the error validation.
 * @param params object members to add or override
 */
const error = (params = {}) =>
  PropTypes.shape({ statusCode: PropTypes.number, uid: PropTypes.string, ...params });

/**
 * Definition of the filters object
 */
const filters = PropTypes.arrayOf(
  PropTypes.shape({
    /** The id of the filter (Ex: 'v_brand') */
    id: PropTypes.string,
    /** Each filter item that's selected, separated by dashes (Ex: 'ambia-novel') */
    elementId: PropTypes.string,
    /** The type of filter that it is (Ex: 'multiSelectFilter') */
    type: PropTypes.string,
    /** All selected filter items in array format (Ex: ['ambia', 'novel']) */
    values: PropTypes.arrayOf(PropTypes.string),
  }),
);

/**
 * The detailed error object, as it is found in the 'extensions.errors' property of API responses
 */
const formError = PropTypes.shape({
  arguments: PropTypes.arrayOf(PropTypes.node),
  message: PropTypes.string,
  messageKey: PropTypes.string,
  reason: PropTypes.string,
  restType: PropTypes.string,
  subject: PropTypes.string,
  subjectType: PropTypes.string,
  type: PropTypes.string,
});

/**
 * Definition of Graphql errors
 */
const grahpqlErrors = PropTypes.arrayOf(
  PropTypes.shape({
    /** Description of the error ocurred */
    message: PropTypes.string,
    /** Location of the error in the code */
    location: PropTypes.arrayOf(
      PropTypes.shape({
        line: PropTypes.number,
        column: PropTypes.number,
      }),
    ),
    /** Specific graphql error code */
    extensions: PropTypes.shape({
      /** Http error code */
      statusCode: PropTypes.number,
      /** Internal Apollo error code */
      code: PropTypes.string,
    }),
  }),
);
const graphqlResult = (resultAndAdditionalFields) =>
  PropTypes.shape({
    errors: grahpqlErrors,
    loading: PropTypes.bool,
    ...resultAndAdditionalFields,
  });

/**
 * All Price Related Data
 * for generating prices see: https://hydra.xxxl-dev.at/lz/#price
 */

const priceData = PropTypes.shape({
  // The current price Object containing all information of the current price
  currentPrice: PropTypes.shape({
    // Isocode for internalization
    currencyIso: PropTypes.string,
    // Time until the current price is valid
    endTime: PropTypes.string,
    specialOfferTypeData: PropTypes.shape({ numberOfAsterisks: PropTypes.number }),
    value: PropTypes.number,
  }),
  // The old price to show the difference between the old and current price
  oldPrice: PropTypes.shape({
    currencyIso: PropTypes.string,
    value: PropTypes.number,
  }),
  // The price per unit
  pricePerUnit: PropTypes.shape({
    value: PropTypes.number,
  }),
  // The unit e.g. litre, square meter, etc.
  sellingUnit: PropTypes.string,
  // The amount of the units
  sellingAmount: PropTypes.number,
  /**
   * The percentage of the saved price
   * TODO: remove string type when the following tasks is (merged WXS-3270)
   */
  savedPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // The shipping cost
  shippingCost: PropTypes.shape({
    value: PropTypes.number,
  }),
  // The post cost
  postCost: PropTypes.shape({
    value: PropTypes.number,
  }),
  // The delivery cost
  deliveryCost: PropTypes.shape({
    value: PropTypes.number,
  }),
});

/**
 * All SEO relevant data, which is commonly received as meta info.
 */
const seoData = PropTypes.shape({
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  noFollow: PropTypes.bool,
  noIndex: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
});

/**
 * Defines data which is necessary for the react-tracking package
 */
const tracking = PropTypes.shape({
  trackEvent: PropTypes.func,
});

/**
 * Defines data which is necessary to render/display an image correctly
 */
const { image } = Types;

/**
 * Defines data which is necessary to render/display a video correctly
 */
const { video } = Types;

const productAttributes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
);

const ColliDataType = PropTypes.shape({
  height: PropTypes.number,
  length: PropTypes.number,
  width: PropTypes.number,
  weight: PropTypes.number,
});

const productColliDataType = PropTypes.arrayOf(ColliDataType);

/**
 * Definition of a link.
 */
const link = PropTypes.shape({
  linkName: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string,
});

/**
 * Definition of Product list  types.
 */
const listType = PropTypes.oneOf(['RESERVATION', 'ORDER']);

/**
 * Definition of Cms Media types.
 */
const cmsMedia = PropTypes.shape({
  /** Sets the alt text attribute to the image. */
  altText: PropTypes.string,
  /** Filename of the media */
  filename: PropTypes.string,
  /** Filetype of the media: image or video */
  fileType: PropTypes.string,
  /** Hashcode of the media */
  hashCode: PropTypes.string,
  /** Sets the source set for the image. The source set is only used if `useSrcSet` is set to true. Example: "stuhl-160.jpg 160w, stuhl-320.jpg 320w" */
  source: PropTypes.oneOfType([PropTypes.string, Types.image, Types.video]),
});

/**
 * Definition of data which is necessary to render a categorySlide
 */
const categorySlide = PropTypes.shape({
  /** Code of the category to be displayed */
  code: PropTypes.string.isRequired,
  /** Sets a class to give customized styles. */
  className: PropTypes.string,
  /** Array that holds all data for the categories */
  image: image.isRequired,
  /** Name of the category to be displayed */
  name: PropTypes.string.isRequired,
  /** Quantity to be shown */
  total: PropTypes.number,
  /** The element will be a link if we provide a url */
  url: PropTypes.string,
});

const pointOfService = PropTypes.shape({
  address: PropTypes.shape({
    formattedAddress: PropTypes.string,
    streetname: PropTypes.string,
    streetnumber: PropTypes.string,
    postalCode: PropTypes.string,
    town: PropTypes.string,
  }),
  name: PropTypes.string,
  seoData: PropTypes.shape({
    url: '',
  }),
});

const subsidiary = PropTypes.shape({
  availableReservation: PropTypes.number,
  deliveryTime: PropTypes.number,
  distance: PropTypes.number,
  formattedFloor: PropTypes.string,
  formattedSubsidiaryStockStatus: PropTypes.string,
  inSubsidiaryStockStatus: PropTypes.number,
  pointOfService,
  reservableInSubsidiary: PropTypes.bool,
  selfServiceDeliveryTime: PropTypes.string,
});

const selfServiceDataList = PropTypes.arrayOf(subsidiary);

export default {
  AVAILABILITY_STATUS,
  availabilityStatus,
  breadCrumbs,
  cart,
  categorySlide,
  characteristicData,
  cmsMedia,
  cmsPageData,
  cmsProductContentData,
  cmsProductSpecificData,
  cmsTemplateData,
  contactData,
  DELIVERY_TYPES,
  deliveryTypes,
  energyEfficiency,
  error,
  filters,
  formError,
  graphqlResult,
  priceData,
  productAttributes,
  seoData,
  selfServiceDataList,
  subsidiary,
  tracking,
  image,
  video,
  link,
  listType,
  productColliDataType,
};
