export const rails = '(moebelix|moe?max|poco|xxxlutz|xxxlesnina)';
export const ccTLDs = '([a-z]{2})';

const hostnameRegex = new RegExp(`${rails}[.-]${ccTLDs}(?:[.-](t|o|qc|p|mock|play)[.-])?`);

// eslint-disable-next-line no-undef
const envVars = typeof process !== 'undefined' ? process.env : {};

export function parseHost(hostname, fallbackEnv) {
  const matches = hostnameRegex.exec(hostname);

  if (Array.isArray(matches)) {
    const [, rail, tld, serverEnv] = matches;

    return {
      rail,
      tld,
      env: serverEnv || fallbackEnv || envVars.SERVER_ENV || CONFIG.SERVER_ENV,
    };
  }

  return undefined;
}

export const parseHostFromEnvironment = (host) => {
  const parsedHostObject = parseHost(host);
  if (!parsedHostObject) {
    if (!CONFIG.IS_SSR) {
      // eslint-disable-next-line no-underscore-dangle
      const { env, languageCode } = window?.__SSR_FALLBACK_CONFIG__ ?? {
        env: CONFIG.SERVER_ENV,
        languageCode: CONFIG.COUNTRY,
      };
      return {
        env,
        tld: languageCode,
      };
    }

    return {
      env: envVars.SERVER_ENV || CONFIG.SERVER_ENV,
      tld: envVars.TLD || CONFIG.COUNTRY,
    };
  }
  return parsedHostObject;
};
