import { PRELOAD_QUERY } from 'pages/App/components/ApplicationDataProvider/Preload.query';
import { type ApolloClient } from '@apollo/client';

const getApplicationConfig = async (apolloClient: ApolloClient<object>) => {
  const response = await apolloClient.query({
    query: PRELOAD_QUERY,
    variables: {
      isInSmartEdit: false,
    },
    fetchPolicy: 'cache-first',
  });

  return response.data;
};

export default getApplicationConfig;
