import { type BaseSyntheticEvent } from 'react';
import { filterObject } from '@xxxlgroup/hydra-utils/common';
import { are3rdPartyScriptsAllowed } from 'hooks/use3rdPartyScriptsAllowed';
import { getCookie, userIdCookie } from 'utils/cookie';
import { DynamicContentServiceInterface } from 'services/DynamicContentService/DynamicContentService';
import { TrackingProp } from 'react-tracking';

interface PossibleComponentProps {
  [key: string]: unknown; // This covers all unidentified props.
  id?: string; // An optional unique identifier typically used to track a component instance.
  uid?: string; // An optional unique identifier specifically used for CMS components.
  match?: { url?: string; params?: { category?: string } }; // An optional object containing routing information.
  productData?: { getProduct?: { code?: string } }; // An optional object containing data about a product.
  code?: string; // An optional unique code that might represent a product or other entity.
  variantGroup?: { name?: string }; // An optional object containing information about a product's variant group.
  href?: string; // An optional hyperlink reference used typically in navigation components.
  link?: string; // An optional link that could be used as an alternative or in addition to the href property.
  'data-purpose'?: string; // An optional property used to specify the data's purpose.
  cmsPageData?: {
    getContentPage?: {
      code: string;
      uid: string;
      cmsPageType: { code?: string; cmsPageTypeSubtype?: string };
    };
  }; // Is used to collect data from content pages
}

const extractFromHrefOrLink = (data: PossibleComponentProps): string | null => {
  const pattern = /[-+](c?\d)+$/i;

  if ((data?.href && pattern.test(data.href)) || (data?.link && pattern.test(data.link))) {
    const match = data?.href?.match(pattern) || data?.link?.match(pattern);

    if (match) {
      return match[0].replace(/-/g, '');
    }
  }

  return null;
};

/**
 * Get purpose from components "data-purpose" attribute.
 * We want to collect all data-purpose attributes as a array in the future.
 * Because of performance concerns we don't want to do this right now.
 */
const getPurpose = (element: EventTarget & { value?: string; purpose?: string }) => {
  if (!(element instanceof Element)) {
    return null;
  }

  return element.getAttribute('data-purpose') ?? null;
};

/**
 * Used by {@link tagComponent} to extract a viable id from props. Make sure to
 * return props which identify the component as good as possible.
 * Example: An Input component is defined by its name. The name must not be unique,
 * but considering the context, it gets unique most likely.
 */
export const tagId = (componentName: string, componentProps: PossibleComponentProps) => {
  // Remember: These consts must not exist in all components, but could exist.
  const { id, uid, match, productData } = componentProps;

  if (componentName === 'ProductVariantGroup') {
    return componentProps.variantGroup?.name;
  }

  const productCode = productData?.getProduct ? productData.getProduct.code : componentProps.code;

  // the productId must be preferred
  if (productCode) {
    return productCode;
  }

  // uid for e.g. cms components
  if (uid) {
    return uid;
  }

  // If the component type is NavigationList, navigationItem or link we extract the category code from the href attribute
  if (['Link', 'NavigationItem', 'NavigationList'].includes(componentName)) {
    const category = extractFromHrefOrLink(componentProps);

    if (category !== null) {
      return category;
    }
  }

  // categoryId
  if (componentName === 'Category') {
    return match?.params?.category;
  }

  /**
   * id is a standard that should be returned here. There are some more specific identifiers in case of
   * products, categories or CMS components that are set with conditions above
   */
  if (id) {
    return id;
  }

  // used by API calls, returned as a last resort to identify component origin
  if (match?.url) {
    return match.url;
  }

  // it's ok to return undefined in the end as we have no other identifier
  return undefined;
};

interface ComponentPropsWithReactTracking extends PossibleComponentProps {
  tracking?: TrackingProp; // This prop gets provided by react-tracking package and is used in the tracking function to collect contexts
}

/**
 * Helper function to automatically track a Component
 */
export const tagComponent =
  (componentName: string) => (componentPropsWithReactTracking: ComponentPropsWithReactTracking) => {
    const { tracking: trackingProp, ...componentProps } = componentPropsWithReactTracking;

    const id = tagId(componentName, componentProps);
    const dataPurpose = componentProps['data-purpose'] || componentName;
    let pageData = {};

    const contentPage = componentProps?.cmsPageData?.getContentPage;

    if (contentPage) {
      pageData = {
        cmsPage: {
          code: contentPage.code,
          id: contentPage.uid,
          type: contentPage.cmsPageType?.code,
          subtype: contentPage.cmsPageType?.cmsPageTypeSubtype,
        },
      };
    }

    return {
      ...pageData,
      id,
      type: componentName,
      context: [{ id, type: componentName, dataPurpose }],
    };
  };

/**
 * Define name of window data layer
 */

let previousPathname = '';
let previousQueryParams = '';
const queryParamsWhitelist: string[] = ['v_pos_name', 'trbotest'];

const hasDifferentQueryParams = (): boolean => {
  if (document.location.search !== previousQueryParams) {
    const urlParams = new URLSearchParams(document.location.search);
    const previousUrlParams = new URLSearchParams(previousQueryParams);
    return queryParamsWhitelist.some(
      (param) => urlParams.get(param) !== previousUrlParams.get(param),
    );
  }
  return false;
};

export const trackingDispatch =
  (contentService?: DynamicContentServiceInterface) => (data: Record<string, unknown>) => {
    if (!are3rdPartyScriptsAllowed(document.location.search.toString())) {
      return;
    }

    if (
      typeof window !== 'undefined' &&
      (!CONFIG.IS_PROD || window.location.hostname.endsWith('qc.xxxl-dev.at'))
    ) {
      if (!window.debugTracking) {
        window.debugTracking = (enable: boolean) => {
          if (enable) {
            window.debugTrackingData = [];
            const originalPush = window.debugTrackingData.push;

            sessionStorage.setItem('debugTracking', 'true');

            // eslint-disable-next-line func-names
            window.debugTrackingData.push = function (eventData: any) {
              // eslint-disable-next-line no-console
              console.log(
                'dataLayer-push:',
                eventData?.purpose ?? eventData?.event?.purpose,
                eventData,
              );

              // eslint-disable-next-line prefer-rest-params
              return originalPush.apply(window.debugTrackingData, arguments as unknown as []);
            };

            return `Debug tracking enabled.`;
          }

          sessionStorage.removeItem('debugTracking');
          window.debugTrackingData = undefined;

          return `Debug tracking disabled.`;
        };

        if (sessionStorage.getItem('debugTracking')) {
          window?.debugTracking(true);
        }
      } else if (window.debugTrackingData) {
        window.debugTrackingData.push(data);
      }
    }

    // DO NOT REMOVE THIS VARIABLE! This is used by Webrocket to internally handle data distribution.
    window.dx_datalayer = window.dx_datalayer || [];
    window.dx_datalayer.push(data);

    if (contentService) {
      contentService.collectEvent(data);

      if (
        data.event === 'pageShown' &&
        (document.location.pathname !== previousPathname || hasDifferentQueryParams())
      ) {
        previousPathname = document.location.pathname;
        previousQueryParams = document.location.search;
        contentService.dispatch();
      }
    }
  };

const onlyAllowedTypes = (value: unknown): boolean =>
  ['boolean', 'number', 'string'].includes(typeof value);

type TrackingEvent = BaseSyntheticEvent<
  Event,
  Element & { value?: string; purpose?: string },
  EventTarget & { value?: string; purpose?: string }
>;

const isSecurityCriticalElement = (
  target: (Element & { value?: string }) | (EventTarget & { value?: string }),
) =>
  (target instanceof HTMLTextAreaElement && target.tagName === 'TEXTAREA') ||
  (target instanceof HTMLInputElement &&
    target.tagName === 'INPUT' &&
    !['checkbox', 'radio', 'search'].includes(target.type));

const hasSensitiveData = (event: TrackingEvent) =>
  isSecurityCriticalElement(event.target) || isSecurityCriticalElement(event.currentTarget);

/**
 * track event object
 */

export function trackEvent(
  event: TrackingEvent | string,
  data: { purpose?: string | null } & Record<string, unknown>,
): { purpose?: string | null; isString?: string } {
  const caughtEvent = {
    purpose: data.purpose,
    type: event && typeof event !== 'string' ? event.type : null,
    value: null,
  };

  // if falsy event given, just return the caughtEvent object containing the data-purpose
  if (!event) {
    return caughtEvent;
  }

  if (typeof event === 'string') {
    return { isString: event };
  }

  const { currentTarget, target } = event;

  let value: string | undefined;

  if ((currentTarget && currentTarget.value) || (target && target.value)) {
    value = currentTarget.value ?? target.value;
  }

  caughtEvent.purpose = caughtEvent.purpose ?? getPurpose(currentTarget) ?? getPurpose(target);

  if (value && value.length > 0 && !hasSensitiveData(event)) {
    return { ...caughtEvent, ...{ value } };
  }

  return caughtEvent;
}

interface TrackingData extends Record<string, unknown> {
  component: string;
  event: undefined | Event | null | string | { isString: boolean } | { purpose?: string | null };
}

/**
 * Tracking helper function for usage in arrow functions
 */
export const tracking = (
  entity: { type: string; props?: Record<string, any> },
  event: TrackingEvent | string,
  data: { purpose?: string | null; props?: Record<string, any> } & Record<string, any> = {},
) => {
  const userId = getCookie(userIdCookie);
  const trackingData = { ...data, userId };
  const caughtEvent = trackEvent(event, trackingData);
  const { type, props: entityProps } = entity;

  const props = entityProps ? filterObject(entityProps, onlyAllowedTypes) : null;

  const { props: propsFromData = {}, ...otherData } = trackingData;

  const trackData: TrackingData = {
    component: type,
    event: caughtEvent?.isString ?? caughtEvent,
    props: {
      ...props,
      ...propsFromData,
    },
    ...otherData,
  };

  trackData.purpose ??=
    entityProps?.purpose ?? entityProps?.['data-purpose'] ?? data?.event?.purpose;

  Object.keys(trackData).forEach((key) => {
    if (trackData[key] === null || trackData[key] === undefined) {
      delete trackData[key];
    }
  });

  const eventType = (trackData.event as Event)?.type;

  if (entityProps?.tracking) {
    if (entityProps.tracking.trackEvent && eventType !== 'touchend') {
      entityProps.tracking.trackEvent(trackData);
    } else if (entityProps.tracking.getTrackingData && eventType === 'touchend') {
      const { context } = entityProps.tracking.getTrackingData();
      trackData.context = context;
      trackingDispatch()(trackData);
    }
  } else {
    trackingDispatch()(trackData);
  }
};
