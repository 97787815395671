import React, { type FC, isValidElement, type PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import loadable from '@loadable/component';
import useLanguage from 'hooks/useLanguage';
import { use3rdPartyScriptsAllowed } from 'hooks/use3rdPartyScriptsAllowed';
import FeatureFlag from 'components/FeatureFlag';
import LocationListener from 'components/LocationListener';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import useIsMobileApp from 'hooks/useIsMobileApp';

const LoadableConsentModal = loadable(() => import('@webrocket/hydra-module-webrocket-cmp'), {
  resolveComponent: (components) => components.ConsentModal,
  ssr: false,
});

const JentisTracking: FC<PropsWithChildren> = ({ children }) => {
  const { languageCode, rail, brand } = useLanguage();
  const areExternalsAllowed = use3rdPartyScriptsAllowed();
  const location = useLocation();
  const history = useHistory();
  const isMountedOnCSR = useIsMountedOnCSR();
  const isMobileApp = useIsMobileApp();

  useEffect(() => {
    // window.tgNamespace is checked because it is set by Jentis and we want to make sure that it is
    // only loaded once due to the lack of beeing a Singleton class.
    if (!areExternalsAllowed || window.tgNamespace) {
      return;
    }

    Promise.all([
      import(
        /* webpackChunkName: "jentis-tool", webpackPrefetch: true */ '@webrocket/hydra-module-jentis-tracking-xxxl/src/common/jts'
      ),
      import(
        /* webpackChunkName: "jentis-config", webpackPrefetch: true */ `@webrocket/hydra-module-jentis-tracking-xxxl/src/configs/${brand}`
      ),
    ]).then(([{ default: JentisTrackingXXXL }, { default: localConfig }]) => {
      // eslint-disable-next-line compat/compat
      window.requestIdleCallback(
        () => {
          // checking window.tgNamespace again to prevent a race condition when HMR is reloading
          // faster than jentis is initialized resulting again in a double call.
          if (!window.tgNamespace) {
            // eslint-disable-next-line no-new
            new JentisTrackingXXXL({ rail, languageCode, localConfig });
          }
        },
        {
          timeout: 1000,
        },
      );
    });
  }, [areExternalsAllowed, brand, history, languageCode, rail]);

  if (!areExternalsAllowed) {
    return isValidElement(children) ? children : null;
  }

  return (
    <>
      {!isMobileApp && <LoadableConsentModal location={location} history={history} />}
      {children}
      {isMountedOnCSR && (
        <FeatureFlag code="poseidon.custom.tracking" fallback={<></>}>
          <LocationListener />
        </FeatureFlag>
      )}
    </>
  );
};

export default JentisTracking;
