import { noop } from '@xxxlgroup/hydra-utils/common';
import { type ReactNode } from 'react';

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  // eslint-disable-next-line compat/compat
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    // eslint-disable-next-line compat/compat
    navigator.clipboard.writeText(text);
  }
}

/**
 * Disables/enables scrolling of the background element when some overlaying element is open
 * E.g. when hamburger menu on mobile is open do not scroll page underneath it
 */

function getScrollingClassname(type: string) {
  switch (type) {
    case 'mobile':
      return 'noScrollingMobile';
    case 'desktop':
      return 'noScrollingDesktop';
    default:
      return 'noScrolling';
  }
}

export function disableScrolling(type = 'both') {
  const usedClass = getScrollingClassname(type);
  document.body.classList.add(usedClass);
}

export function enableScrolling(type = 'both') {
  const usedClass = getScrollingClassname(type);
  document.body.classList.remove(usedClass);
}

/**
 * Checks if the value is numeric or empty string (e.g. input fields).
 */
export const isNumericOrEmpty = (value: string | number) => /^$|^\d*$/.test(value.toString());

/**
 * This function is used to replace a dot for a comma
 */
export const replaceDotForComma = (amount: number) => {
  if (!amount) {
    return null;
  }

  return amount.toString().replace('.', ',');
};

/**
 * This function is used to separate letters and numbers
 */
export const splitUnits = (unit?: string | undefined | null) => {
  if (!unit) {
    return {};
  }
  const [baseUnit, powerUnit] = unit.split(/(\d+)/);
  return { baseUnit, powerUnit };
};

/**
 * Remove duplicate objects from array by specifying property to compare objects with
 */
export const removeDuplicates = (inputArray: object[] | ReactNode[], property: string) =>
  (inputArray as []).filter(
    (object, position: number, filterArray) =>
      filterArray
        .map((mapObject) => mapObject[property as keyof typeof mapObject])
        .indexOf(object[property as keyof typeof object]) === position,
  );

/**
 * Checks whether the given selector is a valid query selector.
 * @param selector
 * @returns {boolean}
 */
export const isQuerySelectorValid = (selector: string): boolean => {
  const queryCheck = (selectorToCheck: string) =>
    document.createDocumentFragment().querySelector(selectorToCheck);

  try {
    queryCheck(selector);
  } catch {
    return false;
  }

  return true;
};

/**
 * Scroll element into view if querySelector encounters it
 * @param hash
 * @param options
 */
export const scrollToElement = (
  hash: string,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' },
) => {
  if (!isQuerySelectorValid(hash)) {
    return;
  }

  const element = !CONFIG.IS_SSR && document.querySelector(hash);

  if (element) {
    // wrapping it with setTimeout will help a bit the smooth behavior
    setTimeout(() => element.scrollIntoView(options), 500);
  }
};

/**
 * Scroll to specific position with optional params: endPosition, callback, scroll options and delay time
 * @param endPosition - position to scroll to
 * @param callback - function to be called after scrolling
 * @param scrollOptions - options to be passed to scrollTo
 * @param timeout - delay time (in miliseconds)
 * @param isSSR - flag to indicate if in SSR mode
 */
export const scrollToAnimate = ({
  endPosition = 0,
  callback = noop,
  scrollOptions = { behavior: 'smooth' },
  timeout = 0,
}) => {
  const finalPosition = Math.round(endPosition);
  let isScrolling: ReturnType<typeof setTimeout>;

  const scroll = () => {
    // as the scroll animation takes some time
    window.clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(() => {
      callback();
      window.removeEventListener('scroll', scroll);
    }, 200);
  };

  return setTimeout(() => {
    window.addEventListener('scroll', scroll);
    window.scrollTo({ top: finalPosition, ...scrollOptions } as ScrollToOptions);
  }, timeout);
};

/**
 * Scroll to the top of the page.
 */
export const scrollToTop = () => {
  document.querySelector('html')!.scrollTop = 0;
};
