import PropTypes from 'prop-types';

/**
 * Location of the react-router-dom history object. Exported separately to be able to reuse it without having to inject the whole history
 * if you'd like to validate something special in your use case, just provide values for state params.
 * @param stateParams object members to be added to the state validation
 * @see history
 * @see https://reacttraining.com/react-router/web/api/location location
 */
const location = (stateParams = {}) =>
  PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape(stateParams),
  });

/**
 * The react-router-dom history object, to be injected by HOC
 * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md withRouter (HOC)
 * @see https://github.com/ReactTraining/history history
 *
 */
const history = PropTypes.shape({
  listen: PropTypes.func,
  location: location(),
  push: PropTypes.func,
  replace: PropTypes.func,
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']),
});

/**
 * The react-router match object which provides information about <code>route -> url</code> matching.
 * @param params object members to be added to the params validation
 * @see https://reacttraining.com/react-router/web/api/match match
 */
const match = (params = {}) =>
  PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape(params),
    path: PropTypes.string,
    url: PropTypes.string,
  });

const ref = PropTypes.oneOfType([
  PropTypes.shape({
    current: PropTypes.shape(),
  }),
  PropTypes.func,
]);

export default {
  history,
  location,
  match,
  ref,
};
