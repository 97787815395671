class PageLoadEmitter extends EventTarget {
  promiseQueue = [];

  resourcesLoading = [];

  errors = [];

  running = false;

  addLoadable(Component) {
    if ('load' in Component) {
      this.resourcesLoading.push(Component.load());
    }
  }

  awaitLoadable() {
    return new Promise((resolve) => {
      const promises = this.resourcesLoading.slice();
      this.resourcesLoading.length = 0;
      Promise.all(promises).then(() => {
        requestIdleCallback(resolve);
      });
    });
  }

  addPromise(promise) {
    this.promiseQueue.push(promise);

    if (!this.running) {
      this.running = true;

      this.execute().then(() => {
        this.running = false;
      });
    }
  }

  execute() {
    return this.nextTick()
      .then((data) => {
        setTimeout(() => {
          this.dispatchEvent(new CustomEvent('load', { detail: data }));
        }, 0);
      })
      .catch((error) => {
        this.errors.push(error);
      });
  }

  nextTick = (data = []) => {
    if (CONFIG.IS_SSR) {
      return Promise.resolve();
    }

    return this.awaitLoadable().then(
      () =>
        new Promise((resolveLoadable, rejectLoadable) => {
          requestAnimationFrame(() => {
            if (this.promiseQueue.length) {
              const queue = this.promiseQueue.slice();
              this.promiseQueue.length = 0;

              Promise.all(queue)
                .then(this.nextTick, this.nextTick)
                .then((levelData) => {
                  Array.isArray(data) && data.push(...levelData);
                  resolveLoadable(data);
                })
                .catch(rejectLoadable);
            } else {
              resolveLoadable(data);
            }
          });
        }),
    );
  };
}

export default new PageLoadEmitter();
