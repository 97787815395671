/**
 * getCookie gets the string value of a cookie from the `document.cookie` string
 * by splitting it only twice. No regex, no find.
 *
 * For testability, one can pass the `cookie` string value in `opts`.
 *
 * Returns `undefined` if requested cookie does not exist.
 *
 * @param {*} name - This is the name of the cookie
 */
import { noop } from '@xxxlgroup/hydra-utils/common';

export const getCookie = CONFIG.IS_SSR
  ? noop
  : (name: string, opts: { cookie?: string } = {}) => {
      const { cookie = document.cookie } = opts;

      // Note: adding a space to the left so that it can be done in one split
      const rightPart = ` ${cookie}`.split(` ${name}=`, 2)[1];

      if (rightPart) {
        return rightPart.split(';', 1)[0];
      }

      return undefined;
    };

export const MAX_AGE_NINETY_DAYS = 60 * 60 * 24 * 90 * 1000; // in milliseconds

// This function is just temporary, until all logged-in users are migrated for all shops.
// It's a very limited implementation of `setCookie`.
// If you happen to need it, please consider improving this implementation.
// This code will be removed by task XXXL-33332.
export const setCookie = CONFIG.IS_SSR
  ? noop
  : (
      name: string,
      value: string,
      options: { maxAge: number } = { maxAge: MAX_AGE_NINETY_DAYS },
    ) => {
      const expires = new Date(new Date().getTime() + options.maxAge).toUTCString();
      document.cookie = `${name}=${value};path=/;expires=${expires};sameSite=lax;Secure;`;
    };

export const removeCookie = CONFIG.IS_SSR
  ? noop
  : (name: string) => {
      if (!getCookie(name)) {
        return;
      }
      const expires = new Date(new Date().getTime() - MAX_AGE_NINETY_DAYS).toUTCString();
      document.cookie = `${name}="";path=/;expires=${expires};sameSite=lax;Secure;`;
    };

export const userIdCookie = 'user_id';
