const generateID = () =>
  Date.now() +
  ([] as number[])
    .fill(5)
    .map(() => (Math.random() * 16).toString(16))
    .join('');

const initUser = () => {
  const ids = [
    {
      storage: localStorage,
      key: 'gateUid',
      prefix: 'U',
      as: 'userId',
    },
    {
      storage: sessionStorage,
      key: 'gateSid',
      prefix: 'S',
      as: 'sessionId',
    },
  ];

  const result: Record<string, string> = {};

  ids.forEach(({ key, prefix, storage, as }) => {
    const id = storage.getItem(key) || `${prefix}-${generateID()}`;
    storage.setItem(key, id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[key] = id;
    result[as] = id;
  });

  return result;
};

export default initUser;
