const TEMPLATE_CHAR = {
  string: 'x',
  digit: 'd',
};

const getTemplateFromDateFormat = (dateFormat) => dateFormat.replace(/[ydm]/gi, 'd');

const matchTemplateChar = (valueChar, template, index) => {
  const templateChar = template[index];
  switch (templateChar) {
    case TEMPLATE_CHAR.string:
      return /^\w$/.test(valueChar) ? valueChar : '';
    case TEMPLATE_CHAR.digit:
      return /^\d$/.test(valueChar) ? valueChar : '';
    default:
      if (valueChar === templateChar) {
        return valueChar;
      }
      if (template.length === index + 1) {
        return templateChar;
      }
      return templateChar + matchTemplateChar(valueChar, template, index + 1);
  }
};

const matchTemplate = (value, template) => {
  let result = '';
  for (let i = 0; i < value.length && i < template.length; i += 1) {
    result += matchTemplateChar(value.charAt(i), template, i);
  }
  return result;
};

export const formatPhoneAreaCode = (language) => ({
  exec(value) {
    if (!value) {
      return '';
    }

    const leadingZeros = /^00/;
    if (leadingZeros.test(value)) {
      return value.replace(leadingZeros, '+');
    }

    const { areaCode, domesticPrefix } = language.phone;
    const domesticPrefixRegex = new RegExp(`^${domesticPrefix}`);
    if (domesticPrefixRegex.test(value)) {
      return value.replace(domesticPrefixRegex, `+${areaCode}`);
    }

    return value;
  },
  runOnBlur: true,
});

export const formatPostalCode = (language) => ({
  exec(value) {
    const { template } = language.postalCode;
    return matchTemplate(value, template);
  },
  runOnChange: true,
});

export const formatDateField = (dateFormat) => ({
  exec(value) {
    return matchTemplate(value, getTemplateFromDateFormat(dateFormat));
  },
  runOnChange: true,
});

export const trimDots = {
  exec(value) {
    return value?.replace(/\.+$/, '').replace(/^\.+/, '') || '';
  },
  runOnBlur: true,
};

export const trimSpaces = {
  exec(value) {
    return value?.trim() || '';
  },
  runOnBlur: true,
};

export const capitalize = {
  exec(value) {
    return value?.toUpperCase() || '';
  },
  runOnBlur: true,
};

export const removeAllSpaces = {
  exec(value) {
    // removes all whitespaces, including tab, not only space character
    return value?.replace(/\s/g, '') || '';
  },
  runOnBlur: true,
};

export const removeEmojis = {
  exec(value) {
    if (value) {
      const regex = new RegExp(
        [
          '(?:[\u2700-\u27bf]',
          '|(?:\ud83c[\udde6-\uddff]){2}',
          '|[\ud800-\udbff][\udc00-\udfff]',
          '|[\u0023-\u0039]\ufe0f?\u20e3',
          '|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]',
          '|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]',
          '|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]',
          '|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f',
          '|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]',
          '|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]',
          '|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]',
          '|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf',
          '|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])',
        ].join(''),
        'g',
      );
      return value.replace(regex, '');
    }
    return '';
  },
  runOnChange: true,
};

export const formatDate = (value, dayjs, dateFormat = 'L') =>
  dayjs(value, 'YYYY-MM-DDTHH:mm:ssZZ').format(dateFormat);

/*
 * Formats a phone number in the correct format for links
 */
export const formatPhoneNumber = (number, language) => {
  if (typeof number !== 'number' && typeof number !== 'string') {
    return null;
  }

  const phoneNumber = number.trim();

  // numbers starting without plus or zero are invalid
  if (!/^[+0]/.test(phoneNumber)) {
    return null;
  }

  const phoneNumberSanitized = phoneNumber
    .replace(/\(.*?\)/g, '') // non-greedily remove everything inside parens
    .replace(/[^+0-9]/g, '') // remove all characters that are not plus or digits
    .replace(/^\++/, '+'); // reduce multiple leading plus characters to a single one

  return formatPhoneAreaCode(language).exec(phoneNumberSanitized);
};

// combine salutation, title, first and last name
// make everything lowercase but change every character after a "-" to uppercase if the name is like John Smith-Dow
// later in css we use "text-transform: capitalize" to format first characters after a space etc. correctly
export const formatCustomerName = (salutation, title, firstName, lastName) =>
  `${salutation || ''} ${title || ''} ${firstName || ''} ${lastName || ''}`
    .toLowerCase()
    .replace(/-./g, (match) => match.toUpperCase())
    .trim();
