import { gql } from '@apollo/client';

export const features = `
  features {
    code
    enabled
  }
`;

export const PRELOAD_QUERY = gql`
  query preloaded {
    getMessages
    getMandantConfig {
      additionalConfigurations {
        code
        value
      }
      adyenClientKey
      affirmators {
        iconKey
        link
        priority
        title
        tooltip
      }
      phoneNumberCountries {
        iso
        name
        prefix
        rank
      }
      currency {
        active
        isocode
        name
        symbol
      }
      deliveryCountries {
        isocode
        name
      }
      languages {
        languages {
          isocode
        }
      }
      contactSubTopicsByTopic
      contactTopicsByCategory
      conversionalExternalKey
      conversionalExternalJourneyKey
      deliveryDateBookingTimer
      deliveryFreeShippingBasketThreshold
      experiments
      fhStatsigAbTestConfiguration
      googleMapsApiKey
      legalDocumentTrippTrappUrl
      miraklAdsApiKey
      defaultZipCodeCoordinates {
        zipCode
        longitude
        latitude
      }
      offerPriceViewMode
      poseidonLogoIconName
      postFreeShippingBasketThreshold
      postagePickupFreeShippingBasketThreshold
      postalCarriers {
        code
      }
      postTaxCouponPattern
      preselectedPaymentMethod
      preTaxCouponPattern
      productDatasheetDownloadNameType
      restType
      titles {
        code
        name
      }
      trustedShopId
      userWerkId
      validationPatterns {
        bonuscardCoupon
        coupon
        dateFormat
        email
        imageUploadValidation {
          allowedFileExtensions
          allowedMimeTypes
          maxExtractedFilesSizeZip
          maxFileCountZip
          maxNumberOfFiles
          maxNumberOfFilesEnabled
        }
        multipartFileUploadValidation {
          allowedFileExtensions
          allowedMimeTypes
          maxExtractedFilesSizeZip
          maxFileCountZip
          maxNumberOfFiles
          maxNumberOfFilesEnabled
        }
        phone
        postalcode
        salesTaxNumberPrimary
        salesTaxNumberSecondary
      }
      zenDeskApiKey
    }
    getFeatures {
     ${features}
    }
  }
`;
