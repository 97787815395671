import { gql } from '@apollo/client';

import { cart } from 'graphql-fragments';

export const ADD_CART_ENTRY_MUTATION = gql`
  mutation addCartEntry(
    $cartId: String
    $code: String!
    $offerId: String
    $qty: Int!
    $storeCode: String
    $postalCode: String
    $configuredId: String
    $serviceCodes: String
    $type: String
  ) {
    addCartEntry(cartId: $cartId, code: $code, offerId: $offerId, qty: $qty, configuredId: $configuredId, serviceCodes: $serviceCodes, storeCode: $storeCode, postalCode: $postalCode, type: $type) {
      ${cart}
    }
  }
`;

export const DELETE_CART_ENTRY_MUTATION = gql`
  mutation deleteCartEntry($cartId: String, $entryId: Int!) {
    deleteCartEntry(cartId: $cartId, entryId: $entryId) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_ENTRY_MUTATION = gql`
  mutation updateCartEntry($cartId: String, $entryId: Int!, $qty: Int!, $configuredId: String, $data: ServicesData, $storeCode: String, $deliveryMode: String, $productCode: String) {
    updateCartEntry(cartId: $cartId, entryId: $entryId, qty: $qty, configuredId: $configuredId, data: $data, storeCode: $storeCode, deliveryMode: $deliveryMode, productCode: $productCode) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_MERGE = gql`
  mutation updateCartMerge($cartId: String!) {
    updateCartMerge(cartId: $cartId)
  }
`;

export const UPDATE_CARTS = gql`
  mutation updateCarts($cartId: String!, $cartIdAnon: String!, $type: String!) {
    updateCarts(cartId: $cartId, cartIdAnon: $cartIdAnon, type: $type) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_CARRIER_MUTATION = gql`
  mutation updateCartCarrier($cartId: String!, $carrier: String!) {
    updateCartCarrier(cartId: $cartId, carrier: $carrier) {
      ${cart}
    }
  }
`;
