import EXPERIMENTS from 'config/a-b-testing';

export const getExperiment = (
  experimentKey: string,
  language: { languageCode: keyof typeof EXPERIMENTS },
): any => EXPERIMENTS[language.languageCode]?.[experimentKey];

export const getExperimentFromQuery = (experiment: any) => {
  const params = new URLSearchParams(window.location?.search);
  const variantId = params.get(experiment.key) || null;
  let foundVariant = null;

  if (variantId) {
    const variants = Object.values(experiment.variants);
    const [defaultVariant] = variants;
    foundVariant = variants.find((item) => item === variantId) || defaultVariant;
  }

  return { variantId, foundVariant };
};

export const getExperimentsByLanguage = (language: { languageCode: keyof typeof EXPERIMENTS }) =>
  EXPERIMENTS[language.languageCode] || [];

export const preserveExperimentParams = (pathname: string) => {
  const params = new URLSearchParams(window.location.search);
  const tests = Array.from(params).filter((param) => param[0].search(/^exp_.+_test$/i) !== -1);

  const search = tests.map((test) => `${test[0]}=${test[1]}`).join('&');

  return {
    pathname,
    search,
  };
};
