import getAPIUrl from 'utils/getAPIUrl';

const sendConsents = (languageCode: string) => {
  const consentsUrl = `${getAPIUrl(languageCode)}/consents`;

  return fetch(consentsUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      peerius: window?.jentis?.consent?.engine?.getVendorConsent('cstm_peerius_session'),
    }),
    method: 'POST',
  });
};

export default sendConsents;
