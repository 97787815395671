import { createContext } from 'react';
import { initialState, reducer } from 'pages/ImageSearch/ImageSearch.state';
import { ImageSearchContextValues } from 'pages/ImageSearch/ImageSearch.types';

const ImageSearchContext = createContext<ImageSearchContextValues>({
  dispatch: () => reducer,
  state: initialState,
});

export default ImageSearchContext;
