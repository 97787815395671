import { session, local } from '@xxxlgroup/hydra-utils/storage';
import { getDebugCampaigns } from 'components/PreviewPanel/utils/debugCampaigns';
import type { VisitorInformation } from 'services/VisitorInformationService';
import { VisitorData } from '../../initialiseVisitorData';

type GeneralTrackingData = {
  debugCampaigns: string[];
  location: {
    url: string;
  };
  visitor: {
    dynamicContent: {
      trbo: {
        _trbo_ud: string; // eslint-disable-line camelcase
        shouldTrack: boolean;
        utm_id?: string; // eslint-disable-line camelcase
      };
    };
  } & VisitorInformation;
} & VisitorData;

export const getGeneralTrackingData = (initUserData: VisitorData): GeneralTrackingData => {
  const { location: { search = '' } = {} } = window || {};

  let utmId = new URLSearchParams(search).get('utm_id') ?? null;

  if (utmId) {
    session.setItem('utm_id', utmId);
  } else {
    utmId = session.getItem('utm_id');
  }

  const dynamicContentSessionData = {
    dynamicContent: {
      trbo: {
        _trbo_ud: local.getItem('_trbo_ud'),
        shouldTrack: local.getItem('jentis.consent.data')?.value?.vendors?.trbo,
        ...(utmId && { utm_id: utmId }),
      },
    },
  };

  return {
    ...initUserData,
    visitor: {
      ...initUserData?.visitor,
      ...dynamicContentSessionData,
    },
    debugCampaigns: getDebugCampaigns(search) || [],
    location: {
      url: window.location.href,
    },
  };
};

export const filteredGeneralDataLayer = (data: Record<string, any>[]) =>
  data?.filter(
    // Whitelist main pages and clickable links
    ({ event, type }) =>
      [
        'Home',
        'SearchView',
        'CategoryView',
        'Confirmation',
        'ProductDetailPage',
        'CmsPage',
        'Cart',
        'Favourites',
        'Reservation',
        'HotdealsView',
        'ImageSearch',
      ].includes(type) ||
      (type === 'Link' && event.type !== 'impression'),
  );
