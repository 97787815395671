import React from 'react';
import reactDom from 'react-dom/client';

import { trackingDispatch } from 'utils/tracking/tracking';
import DynamicContentService from 'services/DynamicContentService';

import ContainerClient from 'components/Container/ContainerClient';

import createIntlDataContext from 'Providers/IntlProvider/createIntlDataContext';

import createCSRClient from 'apollo/client';
import initI18n from 'Providers/IntlProvider/initI18n';
import { getCookie, MAX_AGE_NINETY_DAYS, setCookie } from 'utils/cookie';
import createLogger from 'utils/logger';
import { isInSmartEdit } from 'components/SmartEditHandling';

import { loadableReady } from '@loadable/component';
import { createBrowserHistory } from 'history';
import AppBase from 'pages/App/AppBase';
import { COOKIE_NAME, isLanguageSelectorEnabled, isValidLanguageCookie } from 'utils/i18n';
import getAPIUrl from 'utils/getAPIUrl';
import initialiseVisitorData from '../initialiseVisitorData';
import getApplicationConfig from './getApplicationConfig';
import getComingSoon from './getComingSoon';
import registerScrollListener from './registerScrollListener';

const mainApp = {
  main: AppBase,
};

const initRuntime = async () => {
  const country = createIntlDataContext(document.location.host);
  const { language } = country;
  const { languageCode } = language;
  const i18n = await initI18n(languageCode, isInSmartEdit());
  const logger = createLogger();
  const apiUrl = getAPIUrl(languageCode);
  const apolloClient = createCSRClient(apiUrl, languageCode, logger);
  const applicationConfig = await getApplicationConfig(apolloClient);
  const history = createBrowserHistory();
  const visitor = initialiseVisitorData(language);
  const dynamicContentService = new DynamicContentService({ apolloClient, visitor });
  const dispatch = trackingDispatch(dynamicContentService);

  const renderContext = {
    apolloClient,
    country,
    dispatch,
    dynamicContentService,
    history,
    i18n,
    logger,
    visitor,
  };

  const modules: { [key: string]: any } =
    getComingSoon(applicationConfig.getFeatures.features) || mainApp;

  const removeInvalidLanguageCookie = () => {
    if (
      getCookie(COOKIE_NAME) &&
      (!isLanguageSelectorEnabled(applicationConfig.getFeatures.features) ||
        !isValidLanguageCookie(applicationConfig.getMandantConfig, getCookie(COOKIE_NAME) ?? ''))
    ) {
      setCookie(COOKIE_NAME, '', { maxAge: -MAX_AGE_NINETY_DAYS });
      window.location.reload();
    }
  };

  const startModules = (mode: string) => {
    const rootElement = document.getElementById('root');
    if (!rootElement) {
      return;
    }
    const moduleName = rootElement.dataset[mode] ?? '';
    delete rootElement.dataset[mode];
    removeInvalidLanguageCookie();

    if (moduleName in modules) {
      const Module = modules[moduleName];
      // TODO: Fix up types below once all other types are clear
      const App = (
        <ContainerClient {...(renderContext as any)}>
          <Module />
        </ContainerClient>
      );

      if (mode === 'createRoot') {
        const root = reactDom[mode](rootElement);
        root.render(App);
      } else if (mode === 'hydrateRoot') {
        reactDom[mode](rootElement, App);
      }
    } else {
      const errorMessage = `Can't find '${moduleName}' in registered modules`;
      logger(new ReferenceError(errorMessage));
    }
  };

  if (CONFIG.IS_HYDRATED_SSR) {
    await loadableReady(() => {
      startModules('hydrateRoot');
    });
  } else {
    startModules('createRoot');
  }

  registerScrollListener();
};

export default initRuntime;
