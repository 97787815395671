/**
 * API STATSIG Moemax for common
 */
export default {
  at: 'client-JkjzyT4tTxzmMi0EldzizOuryb073i9UEmrKyxHdtAg',
  de: 'client-9P4PPmGSKJqCZ7OjDGIq56tWkd2H6qhiHy4B59aE7hj',
  si: 'client-Fw7ETRgvZdUtrgfL3QOmd8DHnOZNEWaXAxOnVVFRgES',
  hr: 'client-wh7EWZ4IOQ7hTjB2HOrXczQOyNeIKVbdtQ1LlgpwNDA',
  hu: 'client-Cf4dyJcj672o9Gn8M8gRuY3sSnqet2Q647r9n2OvgPl',
  ro: 'client-uJlOhmuKdXl8KsPT2QszKz1PtPk5ERTku651xt0nTDo',
  pl: 'client-uJlOhmuKdXl8KsPT2QszKz1PtPk5ERTku651xt0nTDo',
  ch: 'client-y8uSVUXnjXeVJb3SjidGJvx0DNeix2oeXk8TxdKvVuY',
};
