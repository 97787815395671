import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Content } from '@xxxlgroup/hydra-ui-components';
import { tagComponent } from 'utils/tracking/tracking';
import track from 'react-tracking';
import useRoutes from 'hooks/useRoutes';
import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import { inspirationalImages } from 'pages/ImageSearch/ImageSearch.images';
import { actions } from 'pages/ImageSearch/ImageSearch.types';
import { useImageSearchContext } from 'pages/ImageSearch/ImageSearch.provider';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import { useTracking } from 'utils/tracking/hooks';
import { SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE } from 'pages/ImageSearch/ImageSearch.state';
import styles from 'pages/ImageSearch/components/InspirationalImages/InspirationalImages.scss';

const { SET_INSPIRATIONAL_CROP } = actions;
const trackingData = {
  component: 'InspirationalImages',
};

interface InspirationalImagesProps {
  /** customized styles */
  variant?: string;
}

const InspirationalImages = (props: InspirationalImagesProps) => {
  const { variant } = props;
  const inspirationText = useMessage('imagesearch.landing.inspire.text');
  const routes = useRoutes();
  const tracking = useTracking(props, 'InspirationalImages');
  const { dispatch } = useImageSearchContext();
  const { searchTracking } = useCategoryContext();
  const handleClick = useCallback(
    (id: string, alt: string, crop: any) => (event: React.MouseEvent) => {
      searchTracking.current.method = 'inspo_image';
      searchTracking.current.term = alt;
      dispatch({ type: SET_INSPIRATIONAL_CROP, payload: crop });
      tracking(event, {
        type: 'imageSearch',
        event: { type: 'click', purpose: SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE },
        props: { action: 'Flyout::Inspirational image', image_information: [alt, id] },
      });
    },
    [dispatch, searchTracking, tracking],
  );

  return (
    <div className={classNames(styles.inspirationalImagesWrapper, styles[`${variant}Oriented`])}>
      <Content content={inspirationText} className={styles.inspirationText} />
      <div className={styles.inspirationalImages} data-purpose="inspirational.images">
        {inspirationalImages.map(({ id, alt, src, crop }) => (
          <Link
            href={`/${routes.imageSearchPage.name}/?src=${src}`}
            data-purpose="search.modal.upload"
            onClick={handleClick(id, alt, crop)}
            key={src}
            className={styles.link}
            image_id={id}
            image_name={alt}
            trackingData={trackingData}
          >
            <img src={src} alt={alt} className={styles.image} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default track(tagComponent('InspirationalImages'))(InspirationalImages);
