import { type MouseEvent, type KeyboardEvent } from 'react';

export const openCookieModal = (
  event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLOrSVGElement>,
  url: string | null,
) => {
  if (url === '/cookieConsentLink') {
    event.preventDefault();
    document.dispatchEvent(new Event('jentis.consent.show'));
  }
};
