import loadable from '@loadable/component';

const AbstractProductDetail = loadable(
  () =>
    import(
      /* webpackChunkName:"AbstractProductDetail" */ 'pages/App/components/AbstractProductDetail'
    ),
);
const AllTranslations = loadable(
  () => import(/* webpackChunkName:"alltranslations" */ 'pages/AllTranslations/AllTranslations'),
);
const Brochures = loadable(() => import(/* webpackChunkName:"brochures" */ 'pages/Brochures'));
const Cart = loadable(() => import(/* webpackChunkName:"cart" */ 'pages/Cart'));
const CategoryRoot = loadable(
  () => import(/* webpackChunkName:"categoryRoot" */ 'pages/CategoryRoot'),
);
const CategoryView = loadable(
  () => import(/* webpackChunkName:"categoryView" */ 'pages/CategoryView'),
);
const Checkout = loadable(() => import(/* webpackChunkName:"checkout" */ 'pages/Checkout'));
const CmsPage = loadable(() => import(/* webpackChunkName:"cmsPage" */ 'pages/CmsPage'));
const Consultation = loadable(
  () => import(/* webpackChunkName:"consultation" */ 'pages/Consultation'),
);
const ContactCenter = loadable(
  () =>
    import(/* webpackChunkName:"contactCenter" */ 'modules/contact-center-new/pages/ContactCenter'),
);
const ContactPage = loadable(
  () => import(/* webpackChunkName:"contactPage" */ 'pages/ContactPage'),
);
const CustomerAccount = loadable(
  () =>
    import(
      /* webpackChunkName:"customerAccount" */ 'modules/customer-account/pages/CustomerAccount'
    ),
);
const CustomerCard = loadable(
  () =>
    import(
      /* webpackChunkName:"customerCard" */ 'modules/customer-card/pages/CustomerCard/CustomerCard'
    ),
);
const EVSPage = loadable(() => import(/* webpackChunkName:"emailVerification" */ 'pages/EVSPage'));
const Faq = loadable(() => import(/* webpackChunkName:"Faq" */ 'modules/help-center/pages/Faq'));
const Favourites = loadable(
  () => import(/* webpackChunkName:"favourites" */ 'modules/favourites/pages/Favourites'),
);
const Incidents = loadable(
  () => import(/* webpackChunkName:"incidents" */ 'modules/incidents/pages/Incidents'),
);
const Home = loadable(
  () =>
    import(
      /* webpackChunkName: "home" */
      /* webpackPrefetch: true */
      'pages/Home'
    ),
);
const ProductDetailHotdeals = loadable(
  () => import(/* webpackChunkName: "productDetailHotdeals" */ 'pages/ProductDetailHotdeals'),
);
const HotdealsView = loadable(
  () => import(/* webpackChunkName:"hotdealsView" */ 'pages/HotdealsView'),
);
const ImageSearch = loadable(
  () => import(/* webpackChunkName:"imageSearch" */ 'pages/ImageSearch'),
);
const KitchenAdvisory = loadable(
  () => import(/* webpackChunkName:"kitchenAdvisory" */ 'pages/KitchenAdvisory'),
);
const Newsletter = loadable(() => import(/* webpackChunkName:"newsletter" */ 'pages/Newsletter'));
const OrderInformation = loadable(
  () =>
    import(
      /* webpackChunkName:"orderInformation" */ 'modules/order-information/pages/OrderInformation'
    ),
);
const Orders = loadable(() => import(/* webpackChunkName:"orders" */ 'pages/Orders'));
const RatingsOverview = loadable(
  () => import(/* webpackChunkName:"ratingsOverview" */ 'pages/RatingsOverview'),
);
const RegisterUserConfirmation = loadable(
  () =>
    import(
      /* webpackChunkName:"registerUserConfirmation" */ 'modules/customer-authentication/pages/RegisterUserConfirmation'
    ),
);
const Reservation = loadable(
  () => import(/* webpackChunkName:"reservation" */ 'pages/Reservation'),
);
const ResetPassword = loadable(
  () =>
    import(
      /* webpackChunkName:"resetPassword" */ 'modules/customer-authentication/pages/ResetPassword'
    ),
);
const SearchView = loadable(() => import(/* webpackChunkName:"SearchView" */ 'pages/SearchView'));
const SelfPickup = loadable(
  () => import(/* webpackChunkName:"selfPickup" */ 'modules/online-scheduling/pages/SelfPickup'),
);
const SellerPage = loadable(
  () => import(/* webpackChunkName:"selfPickup" */ 'modules/marketplace/pages/SellerPage'),
);
const SignIn = loadable(
  () => import(/* webpackChunkName:"signIn" */ 'modules/customer-authentication/pages/SignIn'),
);
const SignUp = loadable(
  () => import(/* webpackChunkName:"signUp" */ 'modules/customer-authentication/pages/SignUp'),
);
const StoreOverview = loadable(
  () => import(/* webpackChunkName:"storeOverview" */ 'pages/StoreOverview'),
);
const Subsidiary = loadable(() => import(/* webpackChunkName:"Subsidiary" */ 'pages/Subsidiary'));
const Transporter = loadable(
  () =>
    import(
      /* webpackChunkName: "Transporter" */ 'modules/online-scheduling/pages/Transporter/Transporter'
    ),
);
const Trust = loadable(() => import(/* webpackChunkName:"trust" */ 'pages/Trust'));
const KitchenStudioOverview = loadable(
  () =>
    import(
      /* webpackChunkName:"kitchenStudioOverview" */ 'modules/store-service/pages/KitchenStudioOverview'
    ),
);
const KitchenShowroomDetail = loadable(
  () =>
    import(
      /* webpackChunkName:"kitchenStudioOverview" */ 'modules/store-service/pages/KitchenShowroomDetail'
    ),
);
const RestaurantDetail = loadable(
  () =>
    import(
      /* webpackChunkName:"kitchenStudioOverview" */ 'modules/store-service/pages/RestaurantDetail'
    ),
);
const KitchenStyleFinder = loadable(
  () => import(/* webpackChunkName:"kitchenStyleFinder" */ 'pages/KitchenStyleFinder'),
);

export const featureFlagCheckout = ['poseidon.checkout', 'checkout'];

const getPages = (routes: any) => [
  {
    path: routes.homePage,
    component: Home,
    exact: true,
  },
  {
    path: routes.newsletter,
    component: Newsletter,
    exact: true,
    featureFlag: 'wxs.newsletter',
  },
  {
    path: routes.cmsPage,
    component: CmsPage,
  },
  {
    path: routes.favouritesPage.root,
    featureFlag: 'poseidon.favourites',
    component: Favourites,
  },
  {
    path: routes.incidents.root,
    featureFlag: 'poseidon.incidents',
    component: Incidents,
  },
  {
    path: routes.cart,
    featureFlag: featureFlagCheckout,
    component: Cart,
    exact: true,
  },
  {
    path: routes.reservationPage.root,
    featureFlag: ['poseidon.reservation', 'reservation'],
    component: Reservation,
    exact: true,
  },
  {
    path: routes.checkout.root,
    featureFlag: featureFlagCheckout,
    component: Checkout,
    exact: true,
  },
  {
    path: routes.orderInformationPage.root,
    component: OrderInformation,
  },
  {
    path: routes.productDetailPage,
    component: AbstractProductDetail,
    exact: true,
  },
  {
    path: routes.ratingsOverviewPage,
    component: RatingsOverview,
    exact: true,
  },
  {
    path: routes.productDetailPageHotdeals,
    featureFlag: 'poseidon.hotdeals',
    component: ProductDetailHotdeals,
    exact: true,
  },
  {
    path: [routes.categoryPage],
    component: CategoryView,
    exact: true,
  },
  {
    path: routes.searchPage.root,
    component: SearchView,
    exact: true,
  },
  {
    path: routes.imageSearchPage.root,
    featureFlag: 'poseidon.searchBar.imageSearch.enabled',
    component: ImageSearch,
    exact: true,
  },
  {
    path: routes.orders.confirmation,
    featureFlag: featureFlagCheckout,
    component: Orders,
    exact: true,
  },
  {
    path: routes.hotdealsSearchPage.root,
    featureFlag: 'poseidon.hotdeals',
    component: HotdealsView,
    exact: true,
  },
  {
    path: routes.brochuresPage.root,
    component: Brochures,
    exact: true,
  },
  {
    path: routes.contactcenter.root,
    component: ContactCenter,
  },
  {
    path: routes.subsidiaryPage.root,
    component: Subsidiary,
    exact: true,
  },
  {
    path: routes.category.root,
    component: CategoryRoot,
    exact: true,
  },
  {
    path: routes.customerCardPage.root,
    component: CustomerCard,
  },
  {
    path: routes.customerAccount.root,
    component: CustomerAccount,
  },
  {
    path: routes.customerAuthentication.resetPassword.root,
    component: ResetPassword,
  },
  {
    path: routes.customerAuthentication.signUp.root,
    featureFlag: 'poseidon.registration.enabled',
    component: SignUp,
  },
  {
    path: routes.customerAuthentication.confirmAccount.root,
    component: RegisterUserConfirmation,
  },
  {
    path: routes.customerAuthentication.signIn.root,
    featureFlag: 'poseidon.login.enabled',
    component: SignIn,
  },
  {
    path: routes.timendoPage.root,
    featureFlag: 'poseidon.timendo',
    component: Consultation,
  },
  {
    path: routes.transporter.root,
    component: Transporter,
    featureFlag: 'poseidon.onlineScheduling.transporter.enabled',
  },
  {
    path: routes.selfPickupPage,
    component: SelfPickup,
    featureFlag: {
      code: ['poseidon.transporter.enabled', 'poseidon.selfPickUp.enabled'],
      condition: 'some',
    },
  },
  {
    path: routes.allTranslations,
    component: AllTranslations,
    exact: true,
  },
  {
    path: routes.kitchenAdvisory,
    featureFlag: 'poseidon.poco.kitchenAdvisory',
    component: KitchenAdvisory,
  },
  {
    path: routes.storeOverview.root,
    component: StoreOverview,
    exact: true,
  },
  {
    path: routes.kitchenStudioOverview,
    component: KitchenStudioOverview,
    featureFlag: 'poseidon.kitchen.showroom.overview.enable',
    exact: true,
  },
  {
    path: routes.Faq.root,
    featureFlag: 'poseidon.faqpage.enable',
    component: Faq,
  },
  {
    path: routes.kitchenShowroomDetail,
    featureFlag: 'kitchen.showroom.detail.enabled',
    component: KitchenShowroomDetail,
  },
  {
    path: routes.restaurantDetail,
    featureFlag: 'poseidon.restaurant.detail.enabled',
    component: RestaurantDetail,
  },
  {
    path: routes.sellerPage,
    featureFlag: 'marketplace.enabled',
    component: SellerPage,
  },
  {
    path: routes.sellerPageId,
    featureFlag: 'marketplace.enabled',
    component: SellerPage,
  },
  {
    path: routes.evsPage.root,
    component: EVSPage,
  },
  ...(routes.kitchenStyleFinder
    ? [
        {
          path: routes.kitchenStyleFinder,
          exact: true,
          component: KitchenStyleFinder,
        },
      ]
    : []),
  ...(routes.eTrustmark
    ? [
        {
          path: routes.eTrustmark,
          exact: true,
          component: Trust,
        },
      ]
    : []),
  ...(routes.contactPages
    ? Object.values(routes.contactPages).map(({ url }: any) => ({
        path: url,
        component: ContactPage,
      }))
    : []),
];

export default getPages;
