import isFeatureFlagEnabled, {
  type FeatureFlagStructure,
} from 'components/FeatureFlag/isFeatureFlagEnabled';
import loadable from '@loadable/component';

const ComingSoon = loadable(() => import(/* webpackChunkName:"ComingSoon" */ 'pages/ComingSoon'));

const FF_COMING_SOON = 'poseidon.coming.soon.active';
const comingSoonApp = { main: ComingSoon };

const getComingSoon = (features: FeatureFlagStructure[]) => {
  if (!isFeatureFlagEnabled(features, FF_COMING_SOON)) {
    return null;
  }

  return comingSoonApp; // NOSONAR
};

export default getComingSoon;
