import { ApolloError, useApolloClient } from '@apollo/client';
import { CHECK_CART_QUERY } from 'pages/Checkout/Checkout.query';
import { useCallback, useState } from 'react';
import { Cart } from 'types/types';

const useCheckCartQuery = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const query = useCallback(
    async (currentCart: Cart) => {
      /*
        The below should have been historically a Mutation based on the way it's being used.
        Unfortunately it's not super simple to change this kind of behaviour so the use
        of apollo client is still in place unfortunately.
        */
      setLoading(true);
      const result = await client.query({
        query: CHECK_CART_QUERY,
        fetchPolicy: 'network-only',
        variables: { cartId: currentCart.code },
      });
      setLoading(false);
      if (result.error) {
        setLoading(false);
        setError(result.error);
      }
      return result;
    },
    [client],
  );
  return {
    loading,
    error,
    query,
  };
};

export default useCheckCartQuery;
