import { ConfiguredProductData, Product } from 'graphql-types/generated/types-generated';
import { AvailabilityStatus, Image } from 'utils/product/types';

/**
 * defines if a product is unit based and the unit is packaged based (floors)
 */
export const isProductUnitBased = (product: Product): boolean =>
  !!product.priceData?.productUnitDiffersToPriceUnit;

/**
 * defines if a product is available only in physical stores
 */
export const isProductStatusOnlyInStore = (
  availabilityStatus: Product['availabilityStatus'],
): boolean => availabilityStatus === AvailabilityStatus.ONLY_IN_STORE;

/**
 * defines if a product has a valid eyecatcher
 */
export const isProductWithEyecatcher = (product: Product): boolean =>
  !!product.eyecatchers?.[0]?.style;

/**
 * defines if a product is already configured
 */
export const isProductConfigured = (product: Product): boolean =>
  !!(product.configuredProduct && product.configuredProductData);

/**
 * Configurable products has different data source for code and image than the regular products.
 * This function do parsing before returning an object with the correct code and image.
 */
export const getProductCodeAndImage = (
  product: Product,
): {
  image: Image;
  productCode: Product['code'] | ConfiguredProductData['productNumber'];
} => {
  const { configuredProductData, picture } = product;
  const { altText, cdnFilename, url } = picture ?? {};
  const { altText: configuredAltText, url: configuredUrl } =
    configuredProductData?.images?.[0] ?? {};

  if (isProductConfigured(product)) {
    return {
      image: { altText: configuredAltText ?? '', url: configuredUrl ?? '' },
      productCode: configuredProductData?.productNumber,
    };
  }

  const image = { altText: altText ?? '', [url ? 'url' : 'hashCode']: url || cdnFilename };

  return { image, productCode: product.code };
};
