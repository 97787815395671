import { gql } from '@apollo/client';

import { cart } from 'graphql-fragments';

export const GET_CARTS_QUERY = gql`
  query getCarts($type: String = "ORDER") {
    getCarts(type: $type) {
      carts {
        ${cart}
      }
    }
  }
`;

export const GET_PAYMENTMODES_QUERY = gql`
  query getPaymentModes($cartId: String!, $isAnonymous: Boolean, $isExpress: Boolean) {
    getPaymentModes(cartId: $cartId, isAnonymous: $isAnonymous, isExpress: $isExpress){
      ${cart}
    }
  }`;

export const CHECK_CART_QUERY = gql`
  query checkCart($cartId: String!) {
    checkCart(cartId: $cartId)
  }
`;

export const ADYEN_PAYMENT_MODES_QUERY = gql`
  query getAdyenPaymentModes {
    getAdyenPaymentModes {
      paymentMethods {
        configuration {
          entry {
            key
            value
          }
        }
        brands
        details {
          key
          optional
          type
        }
        name
        type
      }
    }
  }
`;
