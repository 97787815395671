import 'requestidlecallback-polyfill';
import { scrollToAnimate } from 'utils/common';

let lastScrollPosition = 0;

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
window.addEventListener('fullscreenchange', () => {
  if (document.fullscreenElement) {
    lastScrollPosition = document.documentElement.scrollTop;
  } else {
    setTimeout(
      () =>
        scrollToAnimate({
          endPosition: lastScrollPosition,
          scrollOptions: { behavior: 'instant' },
        }),
      300,
    );
  }
});
