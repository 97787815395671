import { session } from '@xxxlgroup/hydra-utils/storage';

const getSearchParam = () => window.location?.search || {};

const setDevMode = CONFIG.IS_SSR
  ? () => false
  : () =>
      new URLSearchParams(getSearchParam()).get('devModeCampaigns') ||
      !!session.getItem('debugCampaigns');

export default setDevMode;
