import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ApolloCache,
  ApolloError,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import useRoutes from 'hooks/useRoutes';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { UPDATE_CART_CARRIER_MUTATION, UPDATE_CART_MERGE } from 'pages/Cart/Cart.mutation';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useCheckCartQuery from 'pages/Cart/hooks/useCheckCartQuery';
import { useHistory, useLocation } from 'react-router';
import {
  CHECKOUT_ERROR_TYPE,
  CHECKOUT_SECTIONS,
  clearCheckoutErrors,
  setCheckoutErrors as setCheckoutLocalStorageErrors,
} from 'utils/checkout';
import { getCurrentCart, setUpdateCartPing, useCartsQuery } from 'utils/cart';
import { Cart } from 'types/types';
import useAddFavourites from 'modules/favourites/hooks/useAddFavourites';
import { getErrorsFromGraphQLResponse } from 'utils/graphQL';

export interface CartPageContextData {
  loading: boolean;
  cart: Cart | null;
  checkCartValidity: () => Promise<void>;
  refetchCart?: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<{ getCarts?: { carts: Cart[] } }>>;
  checkoutError: string | boolean;
  orderCartMerged: boolean;
  cartErrors: ApolloError | undefined;
  recatchCart: () => void;
  expressCheckoutError: string | null;
  setExpressCheckoutError: (value: string | null) => void;
  updateCartCarrier: (
    options: MutationFunctionOptions<
      { updateCartCarrier: Cart },
      OperationVariables,
      DefaultContext,
      ApolloCache<any>
    >,
  ) => Promise<FetchResult<{ updateCartCarrier: Cart }>>;
}

const CartPageContext = createContext<CartPageContextData | null>(null);

const removeDuplications = (messages: any, addToFavourites: any[]) => {
  let seen = false;
  return messages.filter((item: any) => {
    if (item.message === 'product.service.marketplace.cart.entry.removed') {
      addToFavourites.push({
        code: item.arguments[0].value,
        type: null,
      });
      if (!seen) {
        seen = true;
        return true;
      }
      return false;
    }
    return true;
  });
};

export const useCartContext = () => useContext(CartPageContext);

export const CartPageContextProvider: FC<{ children: any }> = ({ children }) => {
  const [currentCart, setCurrentCart] = useState<Cart | null>(null);
  const mounted = useRef(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const checkCart = useCheckCartQuery();
  const newCartMergeEnable = useFeatureFlagsEnabled('login.cartMerge.disabled');

  const routes = useRoutes();
  const [expressCheckoutError, setExpressCheckoutError] = useState<null | string>(null);
  const { addFavourite } = useAddFavourites();
  const [checkoutError, setCheckoutError] = useState(
    new URLSearchParams(location?.search)?.get('error') || false,
  );
  const [loadingAfterLogin, setLoadingAfterLogin] = useState(false);

  const [orderCartMerged, setOrderCartMerged] = useState(false);
  const { isAnonymous } = useAuth();
  const [updateCartMergeMutate] = useMutation(UPDATE_CART_MERGE);
  const orderCarts = useCartsQuery({ evaluateMessages: true });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const onAuthCompleted = useCallback(
    async (loggedIn: boolean) => {
      try {
        setLoadingAfterLogin(loggedIn);
        if (orderCarts.refetch) {
          const result = await orderCarts.refetch();

          const { code: cartId, showMergedCart } = result?.data?.getCarts?.carts[0] ?? {};

          if (showMergedCart) {
            setOrderCartMerged(true);

            if (!newCartMergeEnable) {
              if (cartId) {
                await updateCartMergeMutate({
                  variables: { cartId: cartId.toString() },
                });
              }
            } else {
              setOrderCartMerged(false);
            }
          }
        }
      } catch (err) {
        // Caught by general error handling
      } finally {
        if (mounted.current) {
          setLoadingAfterLogin(false);
        }
      }
    },
    [newCartMergeEnable, orderCarts, updateCartMergeMutate],
  );

  useEffect(() => {
    onAuthCompleted(isAnonymous);
    // The dependency array can't have onAuthCompleted as that changes with the change of `isAnonymous`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymous]);

  const checkCartValidity = useCallback(async () => {
    /*
      The below should have been historically a Mutation based on the way it's being used.
      Unfortunately it's not super simple to change this kind of behaviour so the use
      of apollo client is still in place, unfortunately.
    */

    if (!currentCart) {
      return false;
    }

    const result = await checkCart.query(currentCart);

    if (result.errors) {
      setCheckoutLocalStorageErrors(
        CHECKOUT_SECTIONS.CART,
        getErrorsFromGraphQLResponse(result.errors),
        null,
        CHECKOUT_ERROR_TYPE.EXCEPTION,
      );
      return false;
    }

    if (result.data.checkCart) {
      clearCheckoutErrors();
      setCheckoutError(false);
      history.replace(routes.CART);
    }
    return result.data.checkCart;
  }, [currentCart, history, routes.CART, checkCart]);

  useEffect(() => {
    if (orderCarts.data) {
      const newCart = getCurrentCart(orderCarts.data);
      if (newCart && newCart.messages) {
        const addToFavourites: any[] = [];
        const uniqueErrorMessages = removeDuplications(newCart.messages, addToFavourites);
        addToFavourites.forEach((item) => {
          addFavourite([item]);
        });

        const newNewCart = { ...newCart, messages: uniqueErrorMessages }; // have to do this, because `messages` is readonly
        setCurrentCart(newNewCart);
        setLoading(false);
        setUpdateCartPing();
        return;
      }
      setCurrentCart(newCart);
      setLoading(false);
      setUpdateCartPing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCarts.data]);

  const handleCartUpdate = useCallback(
    async (event: StorageEvent) => {
      if (event?.key === 'updateCart') {
        const refetchedOrderCart = await orderCarts.refetch();
        setCurrentCart(getCurrentCart(refetchedOrderCart.data));
      }
    },
    [orderCarts],
  );

  const recatchCart = useCallback(async () => {
    const refetchedOrderCart = await orderCarts.refetch();
    setCurrentCart(getCurrentCart(refetchedOrderCart.data));
  }, [orderCarts]);

  useEffect(() => {
    window.addEventListener('storage', handleCartUpdate);
    return () => {
      window.removeEventListener('storage', handleCartUpdate);
    };
  }, [handleCartUpdate, orderCarts]);

  const [updateCartCarrier] = useMutation<{ updateCartCarrier: Cart }>(
    UPDATE_CART_CARRIER_MUTATION,
    {
      fetchPolicy: 'network-only',
      update: (_store, { data }) => {
        data?.updateCartCarrier && setCurrentCart(data?.updateCartCarrier);
      },
    },
  );

  const value = useMemo(
    () => ({
      loading: loading || loadingAfterLogin,
      cart: currentCart,
      refetchCart: orderCarts.refetch,
      checkCartValidity,
      checkoutError,
      orderCartMerged,
      cartErrors: orderCarts.error,
      updateCartCarrier,
      expressCheckoutError,
      recatchCart,
      setExpressCheckoutError,
    }),
    [
      checkCartValidity,
      checkoutError,
      currentCart,
      loading,
      loadingAfterLogin,
      orderCartMerged,
      orderCarts.error,
      orderCarts.refetch,
      updateCartCarrier,
      expressCheckoutError,
      recatchCart,
      setExpressCheckoutError,
    ],
  );

  return <CartPageContext.Provider value={value}>{children}</CartPageContext.Provider>;
};

export default CartPageContext;
