import { CATEGORYPAGE_PREFIX, PRODUCT_PAGE_PREFIX, SEARCH_URL } from 'constantsDefinitions';
import { rails, ccTLDs } from './hostParser';

const productionHostnameRegex = new RegExp(`^www\\.${rails}\\.${ccTLDs}$`);

const alphanumericCodeWithVariant = '[0-9a-zA-Z]{8,12}([/-][0-9a-zA-Z]{1,2})?';
const hotdealsCode = '(?<codeHS>offer_\\d{8,}([_]?[0-9a-zA-Z])*)$';
const codeAtStart = `^(/(p/)?)?(?<codeS>${alphanumericCodeWithVariant})(?!.*[\\w/-]+)`;
const codeAtEnd = `-(?<codeE>${alphanumericCodeWithVariant})$`;
const productCodeRegex = new RegExp(`(${hotdealsCode})|(${codeAtStart})|(${codeAtEnd})`);

export const isProductionUrl = (hostname: string) => productionHostnameRegex.test(hostname);

type StringMap = Record<string, string>;

/*
 * @deprecated use native new URL instead:
 * const parsedUrl = new URL(window.location.href);
 * console.log(parsedUrl.searchParams.get("id"));
 * https://developer.mozilla.org/en-US/docs/Web/API/URL
 */
export const getUrlParams = (url: string) => {
  const urlDict: StringMap = {};
  const query = url.substring(1).split('?')[1];
  if (query) {
    const urlSearch = new URLSearchParams(query);
    urlSearch.forEach((value, key) => {
      urlDict[key] = value;
    });
  }
  return urlDict;
};

// this returns a search url including the term
export function getSearchUrl(searchTerm = '') {
  return SEARCH_URL + searchTerm;
}

// this returns a product url by code
export function getProductUrl(productCode = '') {
  return PRODUCT_PAGE_PREFIX + productCode;
}

// this returns a cms categoryPage
export function getCategoryCmsPage(categoryCode = '') {
  return CATEGORYPAGE_PREFIX + categoryCode;
}

// Parses url string to obtain hash/anchor value (string after #)
export const parseHashValue = (url: string) => url?.trim().split('#')[1];

// Parses product code from
// - hotdeals pages: /h/url-friendly-product-name-offer_01234567_01
// - PDP: /p/01234567 or
// - search term: 01234567
export const parseProductCode = (productUrlParam: string, sanitize: boolean = false) => {
  const parsedGroups = productCodeRegex.exec(productUrlParam)?.groups;
  const productCode = parsedGroups?.codeHS ?? parsedGroups?.codeS ?? parsedGroups?.codeE;

  return sanitize ? productCode?.replace(/[/-]/g, '') : productCode;
};

export const injectScript = (props: Partial<HTMLScriptElement>) => {
  const script = document.createElement('script');

  Object.assign(script, {
    async: true,
    type: 'text/javascript',
    ...props,
  });
  document.body.appendChild(script);
};
