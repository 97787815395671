export type ImageSearchCropData = {
  x: number;
  y: number;
  unit: '%' | 'px';
  width: number;
  height: number;
};

export type ImageSearchEntryType = {
  file?: File | null;
  imgSrc?: string | null;
  crop?: ImageSearchCropData;
  products: Array<any>;
  search: Array<string>;
};

export interface ImageSearchProps {
  error?: string | null;
  inspirationalCrop: ImageSearchCropData | null;
  isInitialCrop: boolean;
  isLoading: boolean;
  searches: ImageSearchEntryType[] | [];
  showModal?: boolean;
}

export interface DropzoneErrorType {
  code?: string;
  name?: string;
  message?: string;
  meta: File;
}

export const actions = {
  ADD_ERROR: 'ADD_ERROR',
  ADD_PRODUCTS: 'ADD_PRODUCTS',
  HIDE_MODAL: 'HIDE_MODAL',
  LOADING: 'LOADING',
  REMOVE_ERROR: 'REMOVE_ERROR',
  SET_INSPIRATIONAL_CROP: 'SET_INSPIRATIONAL_CROP',
  SHOW_MODAL: 'SHOW_MODAL',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
};

export const {
  ADD_ERROR,
  ADD_PRODUCTS,
  HIDE_MODAL,
  LOADING,
  REMOVE_ERROR,
  SET_INSPIRATIONAL_CROP,
  SHOW_MODAL,
  UPLOAD_IMAGE,
} = actions;

export interface DispatchProps {
  type:
    | typeof ADD_ERROR
    | typeof ADD_PRODUCTS
    | typeof HIDE_MODAL
    | typeof LOADING
    | typeof REMOVE_ERROR
    | typeof SET_INSPIRATIONAL_CROP
    | typeof SHOW_MODAL
    | typeof UPLOAD_IMAGE;
  payload?: any;
}
export interface ImageSearchContextValues {
  dispatch: (arg1: DispatchProps) => void;
  state: ImageSearchProps;
}
