export enum AvailabilityStatus {
  ONLINE_AVAILABLE = 'ONLINEAVAILABLE',
  ONLY_IN_STORE = 'ONLYINSTORE',
}

export interface Image {
  altText?: string;
  hashCode?: string;
  url?: string;
}
