import PropTypes from 'prop-types';

/**
 * The API provided by {@link https://github.com/joepuzzo/informed informed} if field is wrapped in HOC.
 */
const fieldApi = PropTypes.shape({
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  setTouched: PropTypes.func,
});

/**
 * The raw fieldState object - customizable.
 *
 * @param params object members to add or override
 * @see fieldState
 */
const rawFieldState = (params = {}) =>
  PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.node,
    ...params,
  });

/**
 * The common fieldState object provided by {@link https://github.com/joepuzzo/informed informed}.
 * In some cases, whenever <code>String</code> does not match the value type, watch out for further implementations in this file or create
 * a new state definition.
 */
const fieldState = rawFieldState();

/**
 * Special variant of fieldState whose value is boolean - for radiobuttons, checkboxes...
 * @see fieldState
 */
const booleanFieldState = rawFieldState({ value: PropTypes.bool });

export default {
  booleanFieldState,
  fieldApi,
  fieldState,
};
