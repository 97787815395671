import {
  SidebarContext,
  UnitBasedCalculationExtended,
} from 'pages/ProductDetail/ProductDetails.types';

export const initialStateService = {
  serviceCode: '0000', // placeholder dummy service
};

export const initialStateSidebar: SidebarContext = {
  isOpen: false,
  addedEntry: undefined,
  errors: [],
  variant: undefined,
  potentialOrderPromotions: [],
  potentialProductPromotions: [],
  cartMessages: [],
};

export const initialStateUnitBasedCalculation: UnitBasedCalculationExtended = {
  packageAmount: 1,
  price: {},
  productUnitBasedAmount: '0',
  deliveryAmount: 0,
  hasError: false,
};
