import isFeatureFlagEnabled from 'components/FeatureFlag/isFeatureFlagEnabled';

export const COOKIE_NAME = 'user_selected_language';

export const FLAG_ICONS = {
  it_CH: 'flagItaly',
  de_CH: 'flagGermany',
  fr_CH: 'flagFrance',
};

export const isLanguageSelectorEnabled = (features: any) =>
  isFeatureFlagEnabled(features, 'poseidon.multi.language.support.enabled');

export const isValidLanguageCookie = (config: any, cookieValue: string) =>
  config.languages?.languages?.map((language: any) => language.isocode).includes(cookieValue);
