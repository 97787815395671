import PropTypes from 'prop-types';
import { priceDataProps } from 'types/price-types';
import Types from 'types/common-types';

/** eyecatcher of product */
const eyecatchers = PropTypes.arrayOf(
  PropTypes.shape({ code: PropTypes.string, type: PropTypes.string }),
);

/** media data of product */
const productMediaData = PropTypes.shape({
  datasheets: PropTypes.arrayOf(
    PropTypes.shape({
      cdnFilename: PropTypes.string,
      documentType: PropTypes.string,
      downloadName: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  presentation: PropTypes.arrayOf(
    PropTypes.shape({
      altText: PropTypes.string,
      cdnFilename: PropTypes.string,
      fileType: PropTypes.string,
      realFilename: PropTypes.string,
    }),
  ),
});

const attributes = PropTypes.shape({
  colorMapping: PropTypes.shape({
    code: PropTypes.string,
    targetColor: PropTypes.string,
    webColor: PropTypes.string,
  }),
  dimensions: PropTypes.shape({
    depth: PropTypes.string,
    height: PropTypes.string,
    strRepresentation: PropTypes.string,
    strRepresentationKey: PropTypes.string,
    width: PropTypes.string,
  }),
  material: PropTypes.string,
});

const productTypeAttributes = {
  /** AR links to iOS and Android. */
  augmentedReality: Types.augmentedReality,
  /** Status of product, e.g. ONLYINSTORE, ONLINEAVAILABLE, etc. */
  availabilityStatus: Types.availabilityStatus,
  /** Product code */
  code: PropTypes.string,
  /** Energy efficiency */
  energyEfficiencyData: Types.energyEfficiency,
  /** Eyecatcher of product */
  eyecatchers,
  /** Product ID */
  id: PropTypes.string,
  /** check if configuredProduct */
  isConfiguredProduct: PropTypes.bool,
  /** Array of product images */
  mediaData: productMediaData,
  /** Name of product */
  name: PropTypes.string,
  /** Order data for the product */
  orderData: PropTypes.shape({
    /** Declare if the product is buyable */
    buyable: PropTypes.bool,
    /** Max Orderable Amount */
    maxOrderableAmount: PropTypes.number,
    /** Indicates if it is sealable */
    sellable: PropTypes.bool,
  }),
  /** price object of product */
  priceData: priceDataProps,
  /** URL to the product detail page */
  url: PropTypes.string,
};

const reduceAttributes = (productAttributes) =>
  productAttributes.reduce((keyMap, attribute) => {
    if (attribute in productTypeAttributes) {
      // eslint-disable-next-line no-param-reassign
      keyMap[attribute] = productTypeAttributes[attribute];
    }
    return keyMap;
  }, {});

/**
 * We can generate a prop types shape for product using common properties.
 * @param productProps {[string]} product properties to add to the shape (the key in the productTypeAttributes object)
 * @param additionalPropTypes {object} if you want to complete the shape with more attributes to be checked you can pass an object with the remaining attributes.
 */
const configurableProductTypes = (productProps = [], additionalPropTypes = {}) =>
  PropTypes.shape({ ...reduceAttributes(productProps), ...additionalPropTypes });

export default {
  attributes,
  configurableProductTypes,
  eyecatchers,
  productMediaData,
};
