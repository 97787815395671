import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Content, Dropzone } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import track from 'react-tracking';
import useRoutes from 'hooks/useRoutes';
import useFeatureFlags from 'hooks/useFeatureFlags';
import CmsContent from 'components/CmsContent';
import Layout from 'components/Layout';
import Message from 'components/Message';
import InspirationalImages from 'pages/ImageSearch/components/InspirationalImages';
import useMessage from 'components/Message/useMessage';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';
import { useImageSearchContext } from 'pages/ImageSearch/ImageSearch.provider';
import { disableScrolling } from 'utils/common';
import {
  DropzoneErrorType,
  SET_INSPIRATIONAL_CROP,
  actions,
} from 'pages/ImageSearch/ImageSearch.types';
import { SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE } from 'pages/ImageSearch/ImageSearch.state';
import styles from 'pages/ImageSearch/components/ModalView/ModalView.scss';

const SUPPORTED_IMAGE_MIME_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'].join(
  ', ',
);

const ModalView = (props: any) => {
  const { dispatch, state } = useImageSearchContext();
  const { UPLOAD_IMAGE } = actions;
  const tracking = useTracking(props, 'ModalView');
  const [isInspirationShown] = useFeatureFlags('poseidon.imageSearch.inspiration.enabled');
  const history = useHistory();
  const routes = useRoutes();
  const [dropzoneErrors, setDropzoneErrors] = useState<Array<DropzoneErrorType>>([]);
  const [startSearchButtonTitle, modalText, uploadHint, closeButton, dragover, error] = useMessage([
    'imagesearch.modal.choose.image',
    'imagesearch.modal.text',
    'imagesearch.modal.uploadHint',
    'wxs.modal.closeButton.ariaLabel',
    'wxs.dropzone.dragover',
    'wxs.dropzone.error.headline',
  ]);
  const { searchTracking } = useCategoryContext();

  const handleSelectImage = (file?: File) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const ulImage = new Image();
      ulImage.src = reader.result as string;
      if (reader.result) {
        searchTracking.current.method = 'upload_picture';
        searchTracking.current.redirect = false;
        searchTracking.current.term = state.searches.length.toString();
        history.push({
          pathname: `/${routes.imageSearchPage.name}/`,
          search: `?s=${state.searches.length}`,
        });
        dispatch({ type: UPLOAD_IMAGE, payload: { imgSrc: reader.result, file } });
        dispatch({ type: SET_INSPIRATIONAL_CROP, payload: null });
      }
    };
    reader.readAsDataURL(file);
  };

  const getErrorMessages = useMemo(
    () =>
      ({ maxFileCount, maxSize }: { maxFileCount: number; maxSize: number }) =>
        isArrayEmpty(dropzoneErrors)
          ? []
          : dropzoneErrors.map(
              (dropZoneError: DropzoneErrorType) =>
                (
                  <Message
                    key={dropZoneError.code}
                    code={`wxs.dropzone.error.${dropZoneError.code}`}
                    values={{
                      lastModified: `${dropZoneError.meta.lastModified}`,
                      maxFileCount: `${maxFileCount}`,
                      maxSize: `${maxSize}`,
                      name: dropZoneError.meta.name,
                      size: `${dropZoneError.meta.size}`,
                      type: dropZoneError.meta.type,
                    }}
                  >
                    {(message) => <Content content={message} tag="span" />}
                  </Message>
                ) as unknown as Error,
            ),
    [dropzoneErrors],
  );

  useEffect(() => {
    /**
     * This is not a standard situation for our webshop. When we handle errors, 2 modals are
     * opened simultaniously. Thus we need to keep scrolling disabled for the body and html
     *  when the error handling modal is closed.
     */
    disableScrolling();
  }, [dropzoneErrors]);

  const handleTracking = (event: React.MouseEvent) => {
    tracking(event, {
      type: 'imageSearch',
      event: { type: 'click', purpose: SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE },
      props: { action: 'Flyout::Upload File' },
    });
  };

  const extendWithTrackingProps = { onClick: handleTracking };

  return (
    <>
      <Content content={modalText} className={styles.modalContent} />
      <Dropzone
        acceptedFileTypes={SUPPORTED_IMAGE_MIME_TYPES}
        onChange={(_, files) => handleSelectImage(files[0]?.raw)}
        onError={(_, errors: DropzoneErrorType[] = []) => setDropzoneErrors(errors)}
        className={styles.dropzone}
        maxSize={15 * 1024 * 1024}
        maxFileCount={1}
        errorMessages={getErrorMessages({
          maxFileCount: 1,
          maxSize: 15,
        })}
        errors={dropzoneErrors}
        onModalClose={() => setDropzoneErrors([])}
        files={[]}
        i18n={{
          close: closeButton,
          dragover,
          hint: '',
          uploadLabel: startSearchButtonTitle,
          uploadText: startSearchButtonTitle,
          headline: error,
        }}
        {...extendWithTrackingProps}
      />
      <Content content={uploadHint} className={styles.legalText} />
      {isInspirationShown ? (
        <InspirationalImages variant="column" />
      ) : (
        <Layout variant="full" margin="none">
          <CmsContent pageCode="image_search_page" contentSlot="modal" hideError />
        </Layout>
      )}
    </>
  );
};

export default track(tagComponent('ModalView'))(ModalView);
