/** Smallest font size allowed for Headings */
export const SMALLEST_FONTSIZE = 7;
/** Smallest level of SEO tags allowed for Headings */
export const SMALLEST_SEO = 4;
/** Maximum number of columns allowed for the CmsMainContainer */
export const MAX_NUMBER_OF_COLUMNS = 5;

/** extracts the number from the levelText */
export const getLevel = (levelText: string, minLevel: number) => {
  const foundNumber = levelText.match(/\d/);

  if (foundNumber) {
    return Math.min(parseInt(foundNumber[0], 10), minLevel);
  }

  return minLevel;
};
