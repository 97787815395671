import React, { useContext, useMemo, useReducer, ReactNode } from 'react';
import { initialState, reducer } from 'pages/ImageSearch/ImageSearch.state';
import { ImageSearchProps, DispatchProps } from 'pages/ImageSearch/ImageSearch.types';
import ImageSearchContext from 'pages/ImageSearch/ImageSearch.context';

export interface AuthContextProviderProps {
  children: ReactNode;
}

export interface ContextInterface {
  dispatch: (arg1: DispatchProps) => void;
  state: ImageSearchProps;
}

export const useImageSearchContext = () => useContext(ImageSearchContext);

export const ImageSearchContextProvider = ({ children }: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer<
    (arg1: ImageSearchProps, actions: DispatchProps) => ImageSearchProps
  >(reducer, initialState);
  const context: ContextInterface = useMemo(
    () => ({
      dispatch,
      state,
    }),
    [state, dispatch],
  );

  return <ImageSearchContext.Provider value={context}>{children}</ImageSearchContext.Provider>;
};

export default ImageSearchContextProvider;
