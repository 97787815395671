import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from 'pages/App/components/AccessibleHelmetTitleWrapper/AccessibleHelmetTitleWrapper.scss';

const AccessibleHelmetTitleWrapper = () => {
  const [title, setTitle] = useState('');
  const onHelmetChange = useCallback(
    (newState) => {
      if (newState?.title && newState.title !== title) {
        setTitle(newState.title);
      }
    },
    [title],
  );

  return (
    <>
      <div
        className={styles.screenreaderOnly}
        role="status"
        aria-live="polite"
        aria-atomic="true"
        aria-relevant="all"
      >
        <span>{title}</span>
      </div>

      <Helmet onChangeClientState={onHelmetChange} />
    </>
  );
};

export default AccessibleHelmetTitleWrapper;
