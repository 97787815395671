import getError, { InfoStack } from './getError';

const CLIENT = 'xxxl-webshop-frontend';

/* istanbul ignore next */
const createLogger =
  () =>
  (error: ReferenceError | null = null, info: InfoStack | null = null, level: string = 'error') => {
    if (error) {
      const data = {
        client: CLIENT,
        error: getError(error, info),
        level,
        userAgent: window.navigator.userAgent,
        userLang: window.navigator.language,
        userUrl: document.location.href,
      };

      if (!CONFIG.IS_PROD) {
        // eslint-disable-next-line no-console
        console.log(data);
      }
    }
  };

export default createLogger;
