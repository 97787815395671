import React, { useContext, useEffect, useMemo } from 'react';
import { PRELOAD_QUERY } from 'pages/App/components/ApplicationDataProvider/Preload.query';
import getVisitorInformation from 'services/VisitorInformationService';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { getCookie, MAX_AGE_NINETY_DAYS, setCookie } from 'utils/cookie';
import { gql, useQuery } from '@apollo/client';
import isFeatureFlagEnabled from 'components/FeatureFlag/isFeatureFlagEnabled';
import { SmartEditContext } from 'components/SmartEditHandling';
import ErrorHandler from 'components/ErrorHandler';
import ApplicationDataContext from 'pages/App/components/ApplicationDataProvider/ApplicationDataContext';

export const OAUTH_TOKEN = 'OauthToken';

// This function is just temporary, until all logged-in users are migrated for all shops.
// Then this code will be removed by task XXXL-33332.
export const getTokenFromMobileCookie = () => {
  const cookie = getCookie(OAUTH_TOKEN);
  if (cookie) {
    const urlDecoded = decodeURIComponent(cookie);
    const base64Decoded = window.atob(urlDecoded);
    const stripped = base64Decoded.replace('ENCODED_', '');
    return JSON.parse(stripped);
  }

  return false;
};

// This function is just temporary, until all logged-in users are migrated for all shops.
// Then this code will be removed by task XXXL-33332.
export const setOauthToken = () => {
  const data = local.getItem(OAUTH_TOKEN) || getTokenFromMobileCookie();

  if (data) {
    setCookie('auth_token', encodeURI(`${data.token_type} ${data.access_token}`), {
      maxAge: data.expires_in * 1000,
    });

    setCookie('refresh_token', data.refresh_token, {
      maxAge: MAX_AGE_NINETY_DAYS,
    });

    // removing token from old frontend, so it does not override poseidon cookies on every starttransformMessages
    local.removeItem(OAUTH_TOKEN);
    // removing mobile cookie token
    document.cookie = `${OAUTH_TOKEN}=; Max-Age=-99999999;`;
  }
};

// This should only be available on the client side. Handled as generator function for testability.
const getUserLookup = () => {
  if (CONFIG.IS_SSR) {
    return {};
  }
  const visitor = getVisitorInformation();

  return {
    id: visitor.sessionId,
    visitorId: visitor,
    isActiveDev: visitor.isActiveDev,
  };
};

const updateMessageFragment = gql`
  fragment updatedMessage on Message {
    translation
  }
`;

/**
 * This service has to be included exactly once - at root level. It preloads application data once (we may think of a refresh mechanism in
 * the future).
 *
 * Resolves mandant specific configuration/application state and provides it to arbitrary parts of the application.
 * Can be extended on demand without introducing further complexity.
 * Components consuming the <code>ApplicationDataContext</code> have access to all options provided by this service.
 */
interface ApplicationDataProviderProps {
  children: React.ReactNode;
}
const ApplicationDataProvider = ({ children }: ApplicationDataProviderProps) => {
  const { isInSmartEdit } = useContext(SmartEditContext);

  const {
    loading,
    client,
    error,
    data: {
      getFeatures: { features = [] } = {},
      getMandantConfig: config = {},
      getMessages: { messages = [] } = {},
    } = {},
  } = useQuery(PRELOAD_QUERY, {
    variables: { isInSmartEdit },
    fetchPolicy: isInSmartEdit ? 'no-cache' : 'cache-first',
  });

  const hasOAuthFF = isFeatureFlagEnabled(features, [
    'poseidon.login.enabled',
    'poseidon.login.migrate.users',
  ]);

  useEffect(() => {
    if (hasOAuthFF) {
      setOauthToken();
    }
  }, [hasOAuthFF]);

  const valuesAndUpdaters = useMemo(
    () => ({
      user: getUserLookup(),
      features,
      config,
      messages,
      error,
      isLoading: loading,
      updateMessage: (code: string, translation: string) => {
        client.writeFragment({
          id: `Message:${code}`,
          fragment: updateMessageFragment,
          data: { translation },
        });
      },
    }),
    [features, config, messages, loading, client, error],
  );

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <ApplicationDataContext.Provider value={valuesAndUpdaters}>
      {children}
    </ApplicationDataContext.Provider>
  );
};

export default ApplicationDataProvider;
