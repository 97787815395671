import PropTypes from 'prop-types';

/** Special offer for price */
const specialOfferTypeData = PropTypes.shape({
  numberOfAsterisks: PropTypes.number,
});

/** Price data */
export const priceDataProps = PropTypes.shape({
  /** The current price Object containing all information of the current price */
  currentPrice: PropTypes.shape({
    /** Isocode for internalization */
    currencyIso: PropTypes.string,
    /** Time until the current price is valid */
    endTime: PropTypes.string,
    /** Special offer for this price */
    specialOfferTypeData,
    /** Value for current price */
    value: PropTypes.number.isRequired,
  }).isRequired,
  /** price for delivery */
  deliveryCost: PropTypes.shape({
    customDeliveryType: PropTypes.bool,
  }),
  /** The old price to show the difference between the old and current price */
  oldPrice: PropTypes.shape({
    /** Isocode for internalization */
    currencyIso: PropTypes.string,
    /** Value for old price */
    value: PropTypes.number,
  }),
  /** The post cost */
  postCost: PropTypes.shape({
    /** Value for post cost */
    value: PropTypes.number,
  }),
  productUnitDiffersToPriceUnit: PropTypes.bool,
  /** The percentage of the saved price */
  savedPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The unit e.g. litre, square meter, etc. */
  sellingUnit: PropTypes.string,
  /** The amount of the units */
  sellingAmount: PropTypes.number,
  /** The shipping cost */
  shippingCost: PropTypes.shape({
    value: PropTypes.number,
  }),
  /** One part of a condition that determines whether the promotional message is shown */
  promotionalMessageShown: PropTypes.bool,
});

export default {
  priceDataProps,
};
