// DOCS: https://developers.xxxl-dev.at/docs/default/component/webshop-frontend/how-to-guides/scrolling-behaviour-during-navigation/

import PageLoadEmitter from 'utils/PageLoadEmitter';
import { isIOSDevice } from 'utils/deviceMatchers';

const scrollYRecord: Record<string, number> = {};

export const waitForElement = (selector: string) =>
  new Promise((resolve) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
      return;
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Timeout to prevent too late scroll
    setTimeout(() => {
      observer.disconnect();
      resolve(null);
    }, 2000);
  });

export const isSelectorValid = (selector: string) => {
  try {
    document.createDocumentFragment().querySelector(selector);
  } catch (error) {
    !CONFIG.IS_PROD &&
      // eslint-disable-next-line no-console
      console.error(
        'Please do not use anchor links (#) for anything except navigational links',
        error,
      );

    return false;
  }

  return true;
};

export const recordScrollPosition = () => {
  const locationKey = window.location.pathname + window.location.search;
  scrollYRecord[locationKey] = window.scrollY;

  return locationKey;
};

export const triggerScroll = (hash: string, scrollPosition: number = 0) => {
  const scrollToHash = () => {
    const selector = decodeURI(hash);

    if (isSelectorValid(selector)) {
      waitForElement(selector).then((element) =>
        (element as HTMLElement)?.scrollIntoView({ behavior: 'smooth' }),
      );
    }
  };

  const scrollToPosition = () => {
    window.scrollTo(0, scrollPosition);
  };

  if (hash) {
    PageLoadEmitter.addEventListener('load', scrollToHash, { once: true });
    PageLoadEmitter.addPromise(Promise.resolve());
  } else if (scrollPosition) {
    PageLoadEmitter.addEventListener('load', scrollToPosition, { once: true });
    PageLoadEmitter.addPromise(Promise.resolve());
  }
};

const isNavigationClick = ({ button, metaKey, altKey, ctrlKey, shiftKey }: MouseEvent) => {
  if (button !== 0) {
    // only allow left clicks
    return false;
  }

  // prevent special left clicks which should not navigate
  return !(metaKey || altKey || ctrlKey || shiftKey);
};

const getTargetElement = <T>(type: string, target: HTMLElement | null) =>
  target?.tagName === type ? (target as T) : (target?.closest(type) as T);

const getDatasetAttribute = (dataset: DOMStringMap | undefined, attribute: string) =>
  JSON.parse(dataset?.[attribute] || 'false');

const createHandleScrollPosition = () => {
  const {
    pathname: pathnameBeforeNavigation,
    hash: hashBeforeNavigation,
    search: searchBeforeNavigation,
  } = document.location;

  // get scrollY for backwards navigation (popstate)
  recordScrollPosition();

  return () => {
    // Give the underlying event callbacks time to execute first so that
    // the location.pathname is updated to the new navigation location in
    // the below function
    requestAnimationFrame(() => {
      const {
        pathname: pathnameAfterNavigation,
        hash: hashAfterNavigation,
        search: searchAfterNavigation,
      } = document.location;

      // reset scroll position to top of page
      // - when page changes by changes within the pathname or search variables
      // - when hash is removed from the url
      if (
        pathnameBeforeNavigation !== pathnameAfterNavigation ||
        searchBeforeNavigation !== searchAfterNavigation ||
        (hashBeforeNavigation !== '' && hashAfterNavigation === '')
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      }
    });
  };
};

interface FormSubmitEvent extends Omit<SubmitEvent, 'target'> {
  target: CustomHTMLFormElement;
}

interface CustomHTMLFormElement extends Omit<HTMLFormElement, 'elements'> {
  elements: HTMLElementsCollection[];
  target: string;
}

interface HTMLElementsCollection {
  validity: {
    valid: boolean;
  };
}

const registerScrollListener = () => {
  // do initial scroll
  triggerScroll(document.location.hash);

  const eventHandler: any = (event: FormSubmitEvent) => {
    const { submitter } = event;
    const { target } = event.target;
    const preserveScroll = getDatasetAttribute(submitter?.dataset, 'preserveScroll');
    const isAsynchronousNavigation = getDatasetAttribute(submitter?.dataset, 'async');

    if (preserveScroll || (target && target !== '_self')) {
      return;
    }

    const areAllFieldsValidated = Array.from(event.target.elements).every((element) => {
      if (element.validity) {
        return element.validity.valid;
      }

      return true;
    });

    if (!areAllFieldsValidated) {
      return;
    }

    const scrollPositionHandler = createHandleScrollPosition();

    if (isAsynchronousNavigation) {
      PageLoadEmitter.addEventListener('load', scrollPositionHandler, {
        once: true,
      });

      PageLoadEmitter.addPromise(Promise.resolve());
    } else {
      scrollPositionHandler();
    }
  };

  document.addEventListener('submit', eventHandler, true);

  document.addEventListener(
    'click',
    (event) => {
      if (isNavigationClick(event)) {
        const anchor = getTargetElement<HTMLAnchorElement>('a', event.target as HTMLElement);

        if (!anchor) {
          return;
        }

        const { hash, dataset, target } = anchor;

        const preserveScroll = getDatasetAttribute(dataset, 'preserveScroll');
        if (preserveScroll || (target && target !== '_self')) {
          return;
        }

        const scrollPositionHandler = createHandleScrollPosition();
        scrollPositionHandler();
        triggerScroll(hash);
      }
    },
    true,
  );

  // for backward navigation ONLY
  window.addEventListener('popstate', () => {
    const triggerScrollYRecord = () => {
      triggerScroll(document.location.hash, scrollYRecord[recordScrollPosition()]);
    };

    requestAnimationFrame(() => {
      /**
        Due to differences in iOS browsers POPstate page flow, an additional delay is
        necessary to ensure accurate scrolling to the desired position.
        Otherwise we can depend on the RequestAnimationFrame to trigger the scroll at the right time.
        */
      if (isIOSDevice()) {
        setTimeout(triggerScrollYRecord, 500);
      } else {
        triggerScrollYRecord();
      }
    });
  });
};

export default registerScrollListener;
