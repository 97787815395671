import { isArrayEmpty, isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { session } from '@xxxlgroup/hydra-utils/storage';
import { addressTypes } from 'modules/customer-account/pages/AddressBook/AddressBook.types';

export const CHECKOUT_SECTIONS = {
  CART: 'cart',
  ADDRESS: 'addressForm',
  PAYMENT: 'paymentOptions',
};

export const CHECKOUT_ERROR_KEYS = {
  CART_CHANGED: 'cart.changed.text',
  CART_EMPTY: 'error.checkout.cartisempty',
  MINIMUM_ORDER_VALUE_NOT_REACHED: 'checkout.minimum.order.value.not.reached',
  NO_SELFSERVICE_SELECTED: 'error.checkout.noselfserviceselected',
  PAYMENT_CANCEL_GENERAL: 'wxs.checkout.payment.cancel',
  PAYMENT_CARD_DATA_REMOVED: 'checkout.payment.creditcard.info.data.removed',
  PAYMENT_CARD_EXPIRED: 'checkout.payment.creditcard.expired',
  PAYMENT_CARD_INVALID: 'checkout.payment.creditcard.invalid',
  PAYMENT_CARD_NOT_ENOUGH_BALANCE: 'checkout.payment.creditcard.nobalance',
  PAYMENT_ERROR_GENERAL: 'wxs.checkout.payment.error',
  PAYMENT_INVOICE_BLOCKED: 'checkout.payment.invoice.blocked',
  PAYMENT_INVOICE_GENERAL: 'checkout.payment.invoice.error',
  PAYMENT_NOT_3D_AUTHENTICATED: 'checkout.payment.3dscure',
  PAYMENT_OPTION_INVALID: 'checkout.payment.method.invalid',
  PAYMENT_PIN_REQUIRED: 'checkout.payment.pin.required',
  PAYMENT_VALIDATION_GENERAL: 'error.payment.validation.general',
  PICKUP_STATION_NOT_ENABLED: 'pickupStation.not.enabled',
  PICKUP_STATION_NOT_SELECTED: 'pickupStation.not.selected',
  // this key is the "fallback" for all cases in which we don't want to show - or don't have - a key
  PLACEORDER_GENERAL: 'error.placeorder.general',
  QUANTITY_CHANGED: 'error.checkout.quantitychanged',
  RESERVATION_QUANTITY_LIMIT_REACHED: 'reservation.entry.quantity.limit.reached',
  SERVICE_ERROR: 'add.product.service.error',
  STORE_NOT_SET: 'store.not.set',
  VOUCHER_ERROR_FREEGIFT: 'voucher.freegift.unavailable',
  VOUCHER_ERROR_GENERAL: 'error.voucher.cantredeem',
};

export const addressTypePrefixes = {
  shipping: 'deliveryAddress',
  billing: 'paymentAddress',
};

export const PAYMENTPROVIDERS = {
  ADYEN: 'ADYEN',
};

export const PAYMENTMODES = {
  COUPON: {
    code: 'coupon',
    pspGroupedCode: 'coupon',
  },
  PREPAY: {
    code: 'prepay',
    pspGroupedCode: 'prepay',
  },
  CREDITCARD_ADYEN: {
    code: 'creditcard_adyen',
    pspGroupedCode: 'creditcard',
    type: 'scheme',
  },
  KLARNA_PAY_LATER_ADYEN: {
    code: 'klarna_pay_later_adyen',
    pspGroupedCode: 'klarna_onaccount',
    type: 'klarna',
  },
  KLARNA_PAY_NOW_ADYEN: {
    code: 'klarna_pay_now_adyen',
    pspGroupedCode: 'klarna_sofort',
    type: 'klarna_paynow',
  },
  ONLINE_BANKING_CZ_ADYEN: {
    code: 'onlineBanking_CZ_adyen',
    pspGroupedCode: 'onlineBanking_CZ',
    type: 'onlineBanking_CZ',
  },
  ONLINE_BANKING_SK_ADYEN: {
    code: 'onlineBanking_SK_adyen',
    pspGroupedCode: 'onlineBanking_SK',
    type: 'onlineBanking_SK',
  },
  PAYPAL_ADYEN: {
    code: 'paypal_adyen',
    pspGroupedCode: 'paypal',
    type: 'paypal',
  },
};

export const PAYMENTMODES_WITH_PAYMENTDATA = new Set([
  PAYMENTMODES.CREDITCARD_ADYEN.code,
  PAYMENTMODES.ONLINE_BANKING_CZ_ADYEN.code,
  PAYMENTMODES.ONLINE_BANKING_SK_ADYEN.code,
]);

export const CHECKOUT_TAX_RANKS = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
};

const logger = console;

// all errors in this list are supported, translated and tested in the frontend and must be shown to customers.
export const isErrorShownInCart = (messageKey) =>
  [
    CHECKOUT_ERROR_KEYS.CART_CHANGED,
    CHECKOUT_ERROR_KEYS.CART_EMPTY,
    CHECKOUT_ERROR_KEYS.MINIMUM_ORDER_VALUE_NOT_REACHED,
    CHECKOUT_ERROR_KEYS.NO_SELFSERVICE_SELECTED,
    CHECKOUT_ERROR_KEYS.PICKUP_STATION_NOT_SELECTED,
    CHECKOUT_ERROR_KEYS.PICKUP_STATION_NOT_ENABLED,
    CHECKOUT_ERROR_KEYS.PLACEORDER_GENERAL,
    CHECKOUT_ERROR_KEYS.STORE_NOT_SET,
  ].includes(messageKey);

export const isPaymentError = (messageKey) =>
  [
    CHECKOUT_ERROR_KEYS.PAYMENT_CARD_DATA_REMOVED,
    CHECKOUT_ERROR_KEYS.PAYMENT_CARD_EXPIRED,
    CHECKOUT_ERROR_KEYS.PAYMENT_CARD_INVALID,
    CHECKOUT_ERROR_KEYS.PAYMENT_CARD_NOT_ENOUGH_BALANCE,
    CHECKOUT_ERROR_KEYS.PAYMENT_NOT_3D_AUTHENTICATED,
    CHECKOUT_ERROR_KEYS.PAYMENT_INVOICE_BLOCKED,
    CHECKOUT_ERROR_KEYS.PAYMENT_INVOICE_GENERAL,
    CHECKOUT_ERROR_KEYS.PAYMENT_OPTION_INVALID,
    CHECKOUT_ERROR_KEYS.PAYMENT_PIN_REQUIRED,
    CHECKOUT_ERROR_KEYS.PAYMENT_ERROR_GENERAL,
    CHECKOUT_ERROR_KEYS.PAYMENT_CANCEL_GENERAL,
    CHECKOUT_ERROR_KEYS.PAYMENT_VALIDATION_GENERAL,
  ].includes(messageKey);

export const getAddressTypePrefix = (addressType) =>
  addressType === addressTypes.billingAddress
    ? addressTypePrefixes.billing
    : addressTypePrefixes.shipping;

const fillSalesTaxNumbers = (primaryValue, secondaryValue = null, tertiaryValue = null) => {
  let salesTaxNumbers = [{ number: primaryValue || '', rank: CHECKOUT_TAX_RANKS.PRIMARY }];
  if (secondaryValue) {
    salesTaxNumbers = [
      ...salesTaxNumbers,
      { number: secondaryValue, rank: CHECKOUT_TAX_RANKS.SECONDARY },
    ];
  }

  if (tertiaryValue) {
    salesTaxNumbers = [
      ...salesTaxNumbers,
      { number: tertiaryValue, rank: CHECKOUT_TAX_RANKS.TERTIARY },
    ];
  }
  return salesTaxNumbers;
};

export const getCompanyAddressData = (values) => {
  const { company } = values;
  const { companyName, salesTaxNumbers } = values?.paymentAddress || values;

  return company
    ? {
        company,
        companyName,
        salesTaxNumbers: fillSalesTaxNumbers(
          salesTaxNumbers?.[0],
          salesTaxNumbers?.[1],
          salesTaxNumbers?.[2],
        ),
      }
    : { company, companyName: null, salesTaxNumbers: null };
};

export const getSalesTaxNumbers = (companyName, salesTaxNumbers) =>
  companyName
    ? {
        company: true,
        companyName,
        salesTaxNumbers: fillSalesTaxNumbers(
          salesTaxNumbers?.[0]?.number,
          salesTaxNumbers?.[1]?.number,
          salesTaxNumbers?.[2]?.number,
        ),
      }
    : { company: false, companyName: undefined, salesTaxNumbers: null };

export const basicAddressKeys = [
  'email',
  'firstName',
  'lastName',
  'gender',
  'postalCode',
  'streetname',
  'streetnumber',
  'titleCode',
  'town',
];

const allAddressKeys = [
  ...basicAddressKeys,
  'addition1',
  'floor',
  'lift',
  'line1',
  'line2',
  'phone',
  'fax',
];

// currently no flag is provided over the api
// as a result we have to check single keys
export const isCartDeliveryAddressDifferent = (
  deliveryAddress,
  paymentAddress,
  comparisonKeys = allAddressKeys,
) =>
  !isObjectEmpty(deliveryAddress) &&
  comparisonKeys.some((key) => paymentAddress?.[key] !== deliveryAddress[key]);

export const getCheckoutErrorMessageForSection = (section) => {
  if (!isArrayEmpty(section)) {
    const [{ arguments: args, messageKey }] = section;
    const errorMessageValues = {};
    if (args) {
      args.forEach((arg, index) => {
        errorMessageValues[index] = arg.value;
      });
    }
    return { code: messageKey, key: `${messageKey}-error`, values: errorMessageValues };
  }
  return null;
};

export const isCoupon = (discount) => discount.voucherType === 'COUPON';

// -------- START CHECKOUT ERROR HANDLING ------------------

export const CHECKOUT_ERROR_TYPE = {
  /** a graph ql or messaging error which is converted automatically */
  EXCEPTION: 'exception',
  /** an error which is build in by client code (using {@link createCheckoutError }) */
  CLIENT: 'client',
};

/**
  Creates an error object which is ready to be processed by the renderer.
  Note that this does not need to be done manually for graphQl errors but only if you want to create a custom, client-generated error message
*/
export const createCheckoutError = (messageKey, fallbackKey, subject, args = []) => ({
  messageKey: messageKey || fallbackKey,
  subject,
  args,
});

const buildCheckoutErrors = (errors, fallbackMessageKey, errorType) => {
  switch (errorType) {
    case CHECKOUT_ERROR_TYPE.CLIENT:
      return Array.isArray(errors) ? errors : [errors];
    case CHECKOUT_ERROR_TYPE.EXCEPTION:
      return errors.map(({ messageKey, subject, arguments: args }) =>
        createCheckoutError(messageKey, fallbackMessageKey, subject, args),
      );
    default:
      logger.error('Please specify a valid error type!');
      return null;
  }
};

/**
 * Get checkout errors from session storage for the given {@link CHECKOUT_SECTIONS section}
 */
export const getCheckoutErrors = (section) => {
  const errorStorage = session.getItem('checkoutErrors');
  return errorStorage ? errorStorage[section] : null;
};

/**
 * Store checkout errors in session storage for the given {@link CHECKOUT_SECTIONS section}.
 * Either hand over graphQl errors or build an error object using {@link createCheckoutError}
 */
export const setCheckoutErrors = (section, errors, fallbackMessageKey, errorType) => {
  let errorStorage = session.getItem('checkoutErrors');
  if (!errorStorage) {
    errorStorage = {};
  }

  errorStorage[section] = buildCheckoutErrors(errors, fallbackMessageKey, errorType);
  session.setItem('checkoutErrors', errorStorage);
};

/**
 * Clear errors for the given {@link CHECKOUT_SECTIONS section}
 */
export const resetCheckoutErrors = (section) => {
  const errorStorage = session.getItem('checkoutErrors');
  if (errorStorage) {
    delete errorStorage[section];
    session.setItem('checkoutErrors', errorStorage);
  }
};

/**
 * Clear all checkout errors
 */
export const clearCheckoutErrors = () => {
  session.setItem('checkoutErrors', {});
};

// -------- END OF CHECKOUT ERROR HANDLING ------------------
