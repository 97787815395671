const linkTypes = {
  email: 'Email',
  phone: 'Phone',
  link: 'Link',
};

const getLinkType = (href) => {
  if (href.startsWith('mailto:')) {
    return linkTypes.email;
  }
  if (href.startsWith('tel:')) {
    return linkTypes.phone;
  }

  return linkTypes.link;
};

export const trackRichText = (event, trackingFunc) => {
  if (event.target.tagName === 'A') {
    const linkText = event.target?.textContent ?? '';
    const href = event.target?.href ?? '';
    const linkType = getLinkType(href);
    trackingFunc(event, { href, linkType, linkText });
  }
};
