import { ApolloClient, gql } from '@apollo/client';
import { session } from '@xxxlgroup/hydra-utils/storage';
import { trackingDispatch } from 'utils/tracking/tracking';
import sendConsents from 'pages/App/sendConsents';
import { VisitorData } from '../../initialiseVisitorData';
import { getGeneralTrackingData, filteredGeneralDataLayer } from './utils';

export const DYNAMIC_SLOTS_QUERY = gql`
  mutation receiveContentSlots($data: String!) {
    receiveContentSlots(data: $data) {
      contentslots
      debug
    }
  }
`;

export interface ContentSlot {
  code: string;
  components: {
    id: string;
  }[];
}

export interface DynamicContentDebug {
  id: string;
  message: string;
}

export interface DynamicSlotsQuery {
  receiveContentSlots: {
    debug: DynamicContentDebug[];
    contentslots: ContentSlot[];
  };
}

export interface DynamicContentServiceInterface extends EventTarget {
  apolloClient: ApolloClient<object>;
  collectEvent: (event: Record<string, unknown>) => void;
  dismissedSlots: string[];
  dismissSlot: (id: string) => string[];
  dispatch: () => Promise<void>;
  lastIndex: number;
  reloadContent: () => void;
  visitor: VisitorData;
}

/**
 * This service class manages the slots of the dynamic content in the application.
 * The Major tasks are to handle upcoming requests of dynamic content and manage dismissed content slots.
 * Furthermore, the service also triggers the requests for receiving content.
 */

class DynamicContentService extends EventTarget implements DynamicContentServiceInterface {
  apolloClient;

  dismissedSlots: string[];

  events: Record<string, unknown>[] = [];

  lastIndex = 0;

  lastPageShownEventData: Record<string, any> | null = null;

  visitor;

  constructor({
    apolloClient,
    visitor,
  }: {
    apolloClient: ApolloClient<object>;
    visitor: VisitorData;
  }) {
    super();

    this.dismissedSlots = session.getItem('dismissedSlots') || [];
    this.apolloClient = apolloClient;
    this.visitor = visitor;

    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-constructor
    document.addEventListener(
      'jentis.consent.engine.vendor-change',
      this.handleConsentChange.bind(this),
    );
  }

  handleConsentChange() {
    sendConsents(this.visitor.shop.country).then(this.reloadContent.bind(this));
  }

  collectEvent(event: Record<string, unknown>) {
    this.events.push(event);
  }

  /**
   * Dismisses campaign for current session
   */
  dismissSlot(id: string) {
    this.dismissedSlots = this.dismissedSlots.filter((slotId) => slotId !== id);

    session.setItem('dismissedSlots', this.dismissedSlots);

    return this.dismissedSlots;
  }

  reloadContent() {
    if (this.lastPageShownEventData) {
      // populating data to tracking
      trackingDispatch(this)(this.lastPageShownEventData);

      // since we're still on the same page, call dispatch manually on purpose!
      this.dispatch();
    }
  }

  async dispatch() {
    this.dispatchEvent(new CustomEvent('loading'));
    const generalTrackingData = getGeneralTrackingData(this.visitor);
    const filteredData = filteredGeneralDataLayer(this.events);
    const slots = [generalTrackingData, ...filteredData];

    // grab the last pageShown event to be able to send it again after consent change
    const pageShownEvent = filteredData.filter((eventData) => eventData.event === 'pageShown');
    if (pageShownEvent.length) {
      this.lastPageShownEventData = { ...pageShownEvent.pop()! };
    }

    this.events.length = 0;

    try {
      const result = await this.apolloClient.mutate<DynamicSlotsQuery>({
        mutation: DYNAMIC_SLOTS_QUERY,
        variables: {
          data: JSON.stringify(slots),
        },
        fetchPolicy: 'no-cache',
      });

      const { debug, contentslots = [] } = result?.data?.receiveContentSlots || {};
      const contentSlots = contentslots.map((slot) => ({
        ...slot,
        components: slot.components.filter(({ id }) => !this.dismissedSlots.includes(id)),
      }));

      this.dispatchEvent(new CustomEvent('updateSlots', { detail: { contentSlots, debug } }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

export default DynamicContentService;
