import type { Image as ImageTypeHydra } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import {
  Image as ImageTypeGenerated,
  CmsMedia as CmsMediaTypeGenerated,
} from 'graphql-types/generated/types-generated';

interface ImageProps extends ImageTypeGenerated {
  targetFormat?: string;
}

interface CmsMedia extends CmsMediaTypeGenerated {
  targetFormat?: string;
}

export const isImageSource = (source?: CmsMediaTypeGenerated | ImageTypeGenerated | null) =>
  typeof source === 'object' &&
  (source?.cdnFilename || source?.filename || source?.hashCode || source?.url);

export const parseHydraImage = (image?: CmsMedia | ImageProps | null): ImageTypeHydra | null =>
  isImageSource(image)
    ? {
        ...image,
        altText: image?.altText ?? undefined,
        cdnFilename: image?.cdnFilename ?? undefined,
        fileExtension: image?.fileExtension ?? undefined,
        filename: image?.filename ?? undefined,
        hashCode: image?.hashCode ?? undefined,
        url: image?.url ?? undefined,
      }
    : null;
