/**
 * id: ID from Google Optimize
 * key: Internal ID for this test (used for URL)
 * variants: the variants for the A/B test
 */
export default {
  at: {
    cmsKitchenPlanningPageTest: {
      id: 'YC7Qk069T3qDbzfiMdR8-Q',
      key: 'exp_cms_kitchen_planning_page_test',
      variants: {
        default: '0',
        v1: '1',
      },
    },
    highlightNavLinkTest: {
      id: 'WpuZoCKiQkSulQzX4MRqWg',
      key: 'exp_highlight_navlink_test',
      variants: {
        default: '0',
        enabled: '1',
      },
    },
    voiceSeachTest: {
      id: '20231020-voice-search',
      key: 'exp_voice_search',
      variants: {
        default: '0',
        enabled: '1',
      },
    },
  },
  ch: {},
  de: {
    collapseAvailability: {
      id: 'sVmUzVvwSxSICd5NpTwvJw',
      key: 'exp_collapsible_availability',
      variants: {
        default: '0',
        v1: '1',
      },
    },
    cmsKitchenPlanningPageTest: {
      id: 'IchYVRpXRuW0s-YQqlYMYg',
      key: 'exp_cms_kitchen_planning_page_test',
      variants: {
        default: '0',
        v1: '1',
      },
    },
    highlightNavLinkTest: {
      id: 'tJTmuY24Sr-rXRFljPPiLQ',
      key: 'exp_highlight_navlink_test',
      variants: {
        default: '0',
        enabled: '1',
      },
    },
    mplpVisualRecommendationsTest: {
      id: '20231107-visualrecos-mplp',
      key: 'exp_mplp_visual_recommendations_test',
      variants: {
        default: '0',
        enabled: '1',
      },
    },
  },
  hr: {},
  hu: {},
  pl: {},
  ro: {},
};
