import { getPriceParts, renderAsterisk } from '@xxxlgroup/hydra-ui-components';
import useLanguage from 'hooks/useLanguage';
import useMessage from 'components/Message/useMessage';
import useMandantConfig from 'hooks/useMandantConfig';
import { Price, ProductPriceData } from 'graphql-types/generated/types-generated';

export enum DiscountedPriceModes {
  UVP = 'UVP',
  UVP_LIGHT = 'UVP_LIGHT',
  REGULAR = 'REGULAR',
  TRIMMED = 'TRIMMED',
}

export const useOldPriceMessageCode = (asteriskCount: number) => {
  const { offerPriceViewMode } = useMandantConfig();

  switch (true) {
    case offerPriceViewMode === DiscountedPriceModes.REGULAR && asteriskCount === 1:
    case offerPriceViewMode === DiscountedPriceModes.UVP_LIGHT && asteriskCount === 1:
    case offerPriceViewMode === DiscountedPriceModes.UVP:
      return 'price.uvp';
    case offerPriceViewMode === DiscountedPriceModes.REGULAR && asteriskCount === 2:
    case offerPriceViewMode === DiscountedPriceModes.UVP_LIGHT && asteriskCount === 2:
    default:
      return 'wxs.price.insteadOf';
  }
};

/**
 *  It returns a correct 'instead of {price}' message for the old price representation
 */
export const useOldPriceMessage = (asteriskCount: number, oldPrice?: Price | null | number) => {
  const { currencyFormat } = useLanguage();
  const messageCode = useOldPriceMessageCode(asteriskCount);

  const { price } = getPriceParts(
    (typeof oldPrice === 'object' && oldPrice?.value) || oldPrice,
    currencyFormat,
  );

  const renderAsteriskCount = asteriskCount ? renderAsterisk(asteriskCount) : '';

  return useMessage(messageCode, {
    oldPrice: `${price}${renderAsteriskCount}`,
  });
};

/**
 *  It's checking id the old price should be crossed with a line
 */
export const checkIfOldPriceIsStrike = (asteriskCount: number) => asteriskCount !== 1;

/**
 *  It's returning accessibility related attributes for the old price
 */
export const generateOldPriceAccessibilityProps = (currentPriceId: string) => ({
  'aria-describedby': `${currentPriceId} legalInformation`,
});

/**
 *  It's checking the old price should be displayed
 */
export const useCheckIfOldPriceIsShown = (
  oldPriceValue: Price['value'],
  isSamePrice: boolean,
  hideUvp: ProductPriceData['hideUvp'],
): boolean => {
  const { offerPriceViewMode } = useMandantConfig();
  return (
    !!oldPriceValue &&
    !isSamePrice &&
    !hideUvp &&
    offerPriceViewMode !== DiscountedPriceModes.TRIMMED
  );
};

/**
 *  It's returning the asterisk (*) count, which is connected with the small notes on the bottom of the page
 */
export const getAsteriskCount = (currentPrice: ProductPriceData['currentPrice']) => {
  const { specialOfferTypeData } = currentPrice || {};
  const { numberOfAsterisks } = specialOfferTypeData || {};

  return numberOfAsterisks || 0;
};
