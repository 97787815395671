import { config } from '@xxxlgroup/hydra-config';
import layoutStyles from 'components/Layout/Layout.scss';

const defaultMaxWidth = layoutStyles.defaultContainerMaxWidth;

interface BreakpointObject {
  minWidth: number;
  size: number;
  dimension: 'px' | 'vw';
}

const getGridImageSizes = (
  columnSpan: number,
  numberOfColumns: number,
  isSingleColumnAllowed: boolean,
) => {
  const renderDefault = `(min-width: ${defaultMaxWidth}) ${defaultMaxWidth}, calc(100vw - 48px)`;

  // Use all available screen sizes for images that take a whole row
  if (numberOfColumns <= columnSpan) {
    return renderDefault;
  }

  const minColumns = isSingleColumnAllowed ? 1 : Math.min(columnSpan, 2);
  const breakpoints: BreakpointObject[] = [
    {
      minWidth: config.breakpoints.xl,
      size: Math.trunc(config.breakpoints.xl / numberOfColumns) * columnSpan,
      dimension: 'px',
    },
    {
      minWidth: config.breakpoints.lg,
      size: (100 / numberOfColumns) * minColumns,
      dimension: 'vw',
    },
    {
      minWidth: config.breakpoints.md,
      size: (100 / 2) * minColumns,
      dimension: 'vw',
    },
    {
      minWidth: config.breakpoints.sm,
      size: (100 / 2) * minColumns,
      dimension: 'vw',
    },
  ];

  const sizes = breakpoints
    .map(({ minWidth, size, dimension }) => `(min-width: ${minWidth}px) ${size}${dimension}`)
    .join(', ');

  return `${sizes}, ${isSingleColumnAllowed ? 50 : 100}vw`;
};

export default getGridImageSizes;
