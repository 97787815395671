import { removeDuplicates } from 'utils/common';
import { ReadFieldFunction, SafeReadonly } from '@apollo/client/cache/core/types/common';
import { Review } from 'graphql-types/generated/types-generated';
import { StoreObject } from '@apollo/client';

type ReviewsForCacheType = { reviews: SafeReadonly<Array<Review> | null> } | undefined;
type ReviewsFieldsPolicyOptions = {
  readField: ReadFieldFunction;
  variables: Record<string, any>;
};

export const ratingsReviewsFieldsPolicy = {
  getProductReviews: {
    keyArgs: ['productCode'],
    merge(
      existing: ReviewsForCacheType,
      incoming: ReviewsForCacheType,
      { readField, variables }: ReviewsFieldsPolicyOptions,
    ) {
      // incoming or existing could still be null, so chain to empty list
      const reviews = (
        variables.currentPage === 0
          ? [...(incoming?.reviews || [])]
          : [...(existing?.reviews || []), ...(incoming?.reviews || [])]
      ).map((review) => ({
        ...review,
        id: readField('id', review as StoreObject),
      }));

      return {
        ...incoming,
        reviews: removeDuplicates(reviews, 'id'),
      };
    },
  },
};
