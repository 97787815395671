import punycode from 'punycode';
import { dayjs } from '@xxxlgroup/xxxl-library-datetime';
import useLanguage from 'hooks/useLanguage';
import { CHECKOUT_TAX_RANKS } from 'utils/checkout';

export const DATE_ERROR_KEY = 'form.errors.date';
export const PRIMARY_TAX_NUMBER_ERROR_KEY = 'form.errors.taxNumber.pattern';
export const SECONDARY_TAX_NUMBER_ERROR_KEY = 'form.errors.secondaryTaxNumber.pattern';

// possibly configurable / mandant specific values (or values which can be changed in BE) should be resolved dynamically
// examples: postal code length, language
// input length should be defined per field and resolved from backend

// attention: those values are not equal to backend validator lengths! This should be fixed in the future.
// Consider this whenever you add new limits.

export const INPUT_LENGTH = {
  PASSWORD_MIN_LENGTH: 8,
  SINGLE_LINE: 60,
  MIN_MULTILINE: 3,
  MAX_MULTI_LINE: 5000,
  MAX_MULTI_LINE_SMALL: 2500,
  MIN_ORDER_NUMBER: 6,
  MAX_ORDER_NUMBER: 16,
  MIN_ORDER_LASTNAME: 1,
  MAX_ORDER_LASTNAME: 56,
  MAX_STREET_NAME: 136,
  MAX_STREET_NUMBER: 136,
  MAX_FLOOR_NUMBER: 72,
  MAX_POSTAL_CODE: 56,
  MAX_ADDITION1: 72,
  MAX_COMPANY_NAME: 35,
};

export const validateNotEmpty = () => ({
  code: 'field.required',
  exec(value?: string | null) {
    return !!value;
  },
});

export const validateMaxLength = (maxLength: number) => ({
  code: 'field.tooLong',
  values: { 0: maxLength },
  exec(value?: string | null) {
    if (value) {
      return value.length <= maxLength;
    }
    return true;
  },
});

export const validateMinLength = (minLength: number) => ({
  code: 'field.tooShort',
  values: { 0: minLength },
  exec(value?: string | null) {
    if (value) {
      return value.length >= minLength;
    }
    return true;
  },
});

export const validatePassword = () => ({
  code: 'form.errors.weakpassword',
  exec(value?: string | null) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(.{8,})$/;
    if (value) {
      return passwordRegex.test(value);
    }
    return true;
  },
});

export const useValidatePostalCode = (
  country: null | { postalCode: { regex: RegExp; template: string } } = null,
) => {
  const language = useLanguage();
  const { postalCode: { regex = /^\d{4}$/i, template = 'dddd' } = {} } = country || language;

  return {
    code: 'form.error.postalcode.invalid',
    values: { 0: template.length },
    exec(value?: string | null) {
      if (regex && value) {
        return regex.test(value);
      }
      return !value;
    },
  };
};

export const validateEmail = () => ({
  code: 'form.errors.validemail',
  exec(value?: string | null) {
    if (!value) {
      return false;
    }

    /*
     * We use built-in email validation https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
     * but we add the condition of having at least one dot (.) in the domain part.
     *
     * Only domain part must be punycoded because of IDNA (see RFC3490/RFC3492)
     */

    const [username, domain, other] = value.split('@');
    if (!domain || other || !domain.includes('.')) {
      return false;
    }

    const inputRef = document.createElement('input');
    inputRef.type = 'email';
    inputRef.value = [username, punycode.toASCII(domain)].join('@');
    const isValid = inputRef.checkValidity();
    return isValid;
  },
});

/**
 * checks a phone number based on the following settings:
 *  - is number starting with '00' or '+'?
 *  - does it contain at least 3 digits afterwards?
 *  - does it furthermore consist entirely of digits, without a zero following the area code?
 *
 * as a test case; 00437 should be valid, same goes for +1123123, whereas 000437 is not (too many leading zeros)
 */
export const validatePhoneNumber = () => ({
  code: 'form.errors.telephone',
  exec(value?: string | null) {
    const phoneRegex = /^(?:00|\+)(?!0)\d{3,}$/;
    if (value) {
      return phoneRegex.test(value);
    }
    return true;
  },
});

export const validateNumber = () => ({
  code: 'form.errors.validnumber',
  exec(value?: string | null) {
    const numberRegex = /^[0-9]+$/;
    if (value) {
      return numberRegex.test(value);
    }
    return true;
  },
});

export const validateOrderNumber = () => ({
  code: 'wxs.validation.ordernumber.error',
  exec(value?: string | null) {
    if (value) {
      return /^\s*[-A-Za-z\d]+\s*$/.test(value);
    }
    return true;
  },
});

// check if date is correct: no days > 31, no months > 12
export const validateDate = (dateFormat = 'L') => ({
  code: DATE_ERROR_KEY,
  values: { dateFormat },
  exec(value?: string | null) {
    if (value) {
      return dayjs(value, dateFormat).format(dateFormat) === value;
    }
    return true;
  },
});

// check if date is in the future
export const validateNotFutureDate = (dateFormat: string, currentDate = new Date()) => ({
  code: DATE_ERROR_KEY,
  exec(value?: string | null) {
    if (value) {
      return dayjs(value, dateFormat).isBefore(currentDate);
    }
    return true;
  },
});

/**
 * check if date is not older than date in past calculated with unitValue and unit
 */
export const validateDateNotOlderThan = (
  dateFormat: string,
  unitValue: string,
  unit = 'year',
  currentDate = new Date(),
) => ({
  code: DATE_ERROR_KEY,
  exec(value?: string | null) {
    if (value) {
      const dateToValidate = dayjs(value, dateFormat);
      const dateInPast = dayjs(currentDate).subtract(parseInt(unitValue, 10), unit);
      return dateInPast.isBefore(dateToValidate);
    }
    return true;
  },
});

// check if date is > 18 years old
export const validateAdultDate = (
  dateFormat: string,
  adultAge = 18,
  currentDate = new Date(),
  code = 'form.errors.date.adult',
) => ({
  code,
  exec(value?: string | null) {
    if (value) {
      const adultDate = dayjs(value, dateFormat).add(adultAge, 'year');
      return adultDate.isBefore(currentDate);
    }
    return true;
  },
});

// check if date is < 18 years old
export const validateChildDate = (
  dateFormat: string,
  adultAge = 18,
  currentDate = new Date(),
  code = 'form.errors.child.more18',
) => ({
  code,
  exec(value?: string | null) {
    if (value) {
      const childDate = dayjs(value, dateFormat).add(adultAge, 'year');
      return childDate.isAfter(currentDate);
    }
    return true;
  },
});

export const validateSalesTaxNumber = (
  pattern: string | null,
  rank = CHECKOUT_TAX_RANKS.PRIMARY,
) => ({
  code:
    rank === CHECKOUT_TAX_RANKS.PRIMARY
      ? PRIMARY_TAX_NUMBER_ERROR_KEY
      : SECONDARY_TAX_NUMBER_ERROR_KEY,
  exec(value?: string | null) {
    if (pattern && value) {
      const taxNumRegex = new RegExp(pattern);
      return taxNumRegex.test(value);
    }
    return true;
  },
});

export const validateTown = () => ({
  code: 'form.error.town.invalid',
  exec(value?: string | null) {
    const townRegex = /^[^_=@:]+$/;
    if (value) {
      return townRegex.test(value);
    }
    return true;
  },
});
