export interface InfoStack {
  componentStack: string;
}

const getError = (error: ReferenceError, info: InfoStack | null = null) =>
  error.stack
    ? {
        componentStackTrace: info?.componentStack,
        errorStackTrace: error.stack,
        message: error.message,
      }
    : error;

export default getError;
