import setDevMode from 'services/VisitorInformationService/setDevMode';
import initUser from './initUser';

export type VisitorInformation = {
  browserLanguage: string;
  browserSize: {
    height: number;
    width: number;
  };
  id?: string;
  isActiveDev?: boolean | string;
  referrer?: string;
  sessionId?: string;
  userAgent: string;
};

const getVisitorInformation = (): VisitorInformation => {
  if (CONFIG.IS_SSR) {
    return {
      browserLanguage: 'en-US',
      browserSize: {
        height: 0,
        width: 0,
      },
      userAgent: 'node',
    };
  }

  const { userId, sessionId } = initUser();

  return {
    browserLanguage: navigator.language,
    browserSize: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
    id: userId,
    isActiveDev: setDevMode(),
    referrer: document.referrer,
    sessionId,
    userAgent: navigator.userAgent,
  };
};

export default getVisitorInformation;
