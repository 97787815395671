import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { Notifications } from '@xxxlgroup/hydra-ui-components';
import { defaultMetaTags, countryBasedMetaTags } from 'config/metaTags';
import CountryContext from 'Providers/IntlProvider/CountryContext';
import { ApplicationDataContext } from 'pages/App/components/ApplicationDataProvider';
import Root from 'components/Root';
import useLanguage from 'hooks/useLanguage';
import ContentBlock from 'components/ContentBlock';
import LastSeenProducts from 'cms/LastSeenProducts';
import Footer from 'components/Footer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import FeatureFlag from 'components/FeatureFlag';
import FlexPopupContainer from 'cms/CmsFlexPopupComponent/components/FlexPopupContainer';
import AddToCartSidebarContainer from 'components/AddToCartSidebar/components/Container/AddToCartSidebarContainer';
import { ERROR_REASONS } from 'components/ErrorHandler/ErrorHandler';
import { Helmet, MetaProps } from 'react-helmet-async';
import AccessibleHelmetTitleWrapper from 'pages/App/components/AccessibleHelmetTitleWrapper/AccessibleHelmetTitleWrapper';
import useFocusHeadingAfterPageLoad from 'hooks/useFocusHeadingAfterPageLoad';
import { useSearchCollector } from 'hooks/useSearchCollector';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import useIsModeHeadless from 'hooks/useIsModeHeadless';
import JentisTracking from 'pages/App/JentisTracking';
import { writeContrastToggleStatusToDOM } from 'components/ContrastToggle/ContrastToggle';
import { useNewHeader } from 'modules/header/Header.conf';

import styles from 'pages/App/App.scss';
import { CartPageContextProvider } from 'pages/Cart/contexts/CartContext';

const CountryOverlay = loadable(
  () => import(/* webpackChunkName:"countryOverlay" */ 'components/CountryOverlay'),
);
const Header = loadable(() => import(/* webpackChunkName:"Header" */ 'components/Header'));
const HeaderModule = loadable(
  () => import(/* webpackChunkName:"HeaderModule" */ 'modules/header/Header'),
);
const Error404 = loadable(
  () => import(/* webpackChunkName:"Error404" */ 'components/ErrorHandler/components/Error404'),
);
const PreviewPanel = loadable(
  () => import(/* webpackChunkName:"previewPanel" */ 'components/PreviewPanel'),
);
const TermsAndConditionsModal = loadable(
  () =>
    import(
      /* webpackChunkName:"TermsAndConditionsModal" */ 'modules/customer-account/components/TermsAndConditionsModal'
    ),
);

const CatchError = () => (
  <Error404 errorReason={ERROR_REASONS[404].catchAll} noIndex isTrackingPageShown />
);

const AppBase = () => {
  const { pages } = useContext(CountryContext) ?? { pages: null };
  const language: { languageCode: string; rail: string; region: string } = useLanguage();
  const isMountedOnCSR = useIsMountedOnCSR();
  const isModeHeadless = useIsModeHeadless();

  const htmlAttr = { lang: language.region };
  const metaTags = [
    ...defaultMetaTags,
    ...(countryBasedMetaTags[language.languageCode] || []),
  ] as MetaProps[];

  // /checkout has a reduced header, therefore set the attribute again
  // to retrieve correct behavior
  useEffect(() => {
    writeContrastToggleStatusToDOM();
  }, []);

  useFocusHeadingAfterPageLoad();
  useSearchCollector();

  const UsedHeader = useNewHeader() ? HeaderModule : Header;

  return (
    <Root id="root" entry="main" mode="hydrate-root">
      <JentisTracking>
        <Notifications className={styles.notificationsContainer}>
          <div className={styles.content}>
            <Helmet htmlAttributes={htmlAttr} meta={metaTags} />
            <UsedHeader />
            <AddToCartSidebarContainer />
            <main id="main" className={styles.main} tabIndex={-1}>
              {!isModeHeadless && isMountedOnCSR && (
                <>
                  <ContentBlock area="general.banner" />
                  <ContentBlock area="general.flyout" />
                  <ContentBlock area="general.overlay" />
                  <FlexPopupContainer />
                </>
              )}
              <AccessibleHelmetTitleWrapper />
              <CartPageContextProvider>
                <Switch>
                  {pages}
                  <Route component={CatchError} />
                </Switch>
              </CartPageContextProvider>
            </main>
            <LastSeenProducts />
            <ApplicationDataContext.Consumer>
              {({ user }) => user?.isActiveDev && isMountedOnCSR && <PreviewPanel />}
            </ApplicationDataContext.Consumer>
            <ScrollToTopButton />
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <FeatureFlag code="poseidon.countryOverlay" fallback={<></>}>
              <CountryOverlay />
            </FeatureFlag>
          </div>
          <Footer />
          <TermsAndConditionsModal />
        </Notifications>
      </JentisTracking>
    </Root>
  );
};

export default AppBase;
