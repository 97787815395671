import { createContext } from 'react';

interface FeatureFlagStructure {
  code: string;
  enabled: boolean;
}
export interface ApplicationDataStructure {
  config?: any;
  features?: FeatureFlagStructure[];
  googleMapsApiKey?: string;
  isLoading?: boolean;
  messages?: Record<string, string>;
  updateMessage?: (key: string, translation: string) => void;
  user?: {
    isActiveDev?: string | boolean;
  };
  validationPatterns?: Record<string, string>;
}
const ApplicationDataContext = createContext({} as ApplicationDataStructure);

export default ApplicationDataContext;
