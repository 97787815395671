export const inspirationalImages = [
  {
    id: 'NC-x92nXltQQL13yt58eUaiA',
    src: 'https://media.xxxlutz.com/i/xxxlutz/PIn8gAYPQlbesz9wu_BfvVOw/?fmt=jpg&w=1200',
    alt: 'Esstisch',
    crop: {
      x: 3,
      y: 40.5,
      unit: '%',
      width: 34.0194,
      height: 49.7499,
    },
  },
  {
    id: 'NCn799DCk7BRow1RPLOCbpEg',
    src: 'https://media.xxxlutz.com/i/xxxlutz/NCn799DCk7BRow1RPLOCbpEg/zirbenbett.png?fmt=jpg&w=1200',
    alt: 'Zirbenbett',
    crop: {
      x: 14.5797,
      y: 32.25,
      unit: '%',
      width: 78.9583,
      height: 57.5,
    },
  },
  {
    id: 'PIoESDtcGX-xBBJ09iscfm2g',
    src: 'https://media.xxxlutz.com/i/xxxlutz/PIoESDtcGX-xBBJ09iscfm2g/?fmt=jpg&w=1200',
    alt: 'CHESTERFIELD-SOFA - Ambia Home',
    crop: {
      x: 8.3945,
      y: 48.2499,
      unit: '%',
      width: 54.7499,
      height: 32,
    },
  },
  {
    id: 'PIayGHzqicB8XAxt2ROWlNPQ',
    src: 'https://media.xxxlutz.com/i/xxxlutz/PIayGHzqicB8XAxt2ROWlNPQ/?fmt=jpg&w=1200',
    alt: 'SIDEBOARD - MID.YOU',
    crop: {
      x: 8.2363,
      y: 26.7507,
      unit: '%',
      width: 83.0985,
      height: 65.2521,
    },
  },
];

export const landingPageImage = {
  altText: 'landing page image for image search',
  cdnFilename: 'NCvA_RIx6pF9asJIMCKKaB4w',
  filename: 'image_search_landing_page.png',
  hashCode: 'NCvA_RIx6pF9asJIMCKKaB4w',
  restType: 'Image',
  source: '',
};
