import { type RefObject, useCallback, useMemo, useRef } from 'react';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { GraphQLErrorWithExtensions, GraphQLExtensionErrors } from 'types/graphql';
import { ApolloError } from '@apollo/client/errors';
import { FormApi } from 'informed';
import { SubmittedValues } from 'modules/customer-authentication/components/SignUpForm/SignUpForm';
import { GraphQLError } from 'graphql';

export const getErrorsFromGraphQLResponse = (
  errors: readonly GraphQLError[] | GraphQLErrorWithExtensions[] | undefined,
) => {
  if (errors && errors.length > 0) {
    const firstError = errors[0];
    const extensions = firstError?.extensions;
    const errs = extensions?.errors;
    const exception = extensions?.exception;

    if (errs) {
      return errs;
    }

    if (exception) {
      return [exception];
    }
  }

  return [];
};

export const getErrorsFromGraphQLRequest = (
  error: ApolloError | undefined,
  formApi: RefObject<FormApi<SubmittedValues> | null> | null = null,
) => {
  const requestErrors: GraphQLExtensionErrors[] = [];
  const validationErrors: GraphQLExtensionErrors[] = [];

  if (error && error.graphQLErrors?.length > 0) {
    const { errors } = error.graphQLErrors[0].extensions || { errors: error.graphQLErrors };

    if (errors.length > 0) {
      errors.forEach((graphQLError: GraphQLExtensionErrors) => {
        if (
          graphQLError.type === 'ValidationError' &&
          graphQLError.subject &&
          graphQLError.subject !== 'captcha'
        ) {
          validationErrors.push(graphQLError);
          formApi?.current && formApi.current.setError(graphQLError.subject, graphQLError.message);
        } else {
          requestErrors.push(graphQLError);
        }
      });
    }
  }

  return [validationErrors, requestErrors];
};

export const getErrorsFromGraphQLCdmRequest = (error: {
  graphQLErrors: GraphQLErrorWithExtensions[];
}) => {
  const requestErrors: GraphQLExtensionErrors[] = [];
  const validationErrors: GraphQLExtensionErrors[] = [];

  if (error && !isArrayEmpty(error.graphQLErrors)) {
    const { errors } = error.graphQLErrors[0].extensions || { errors: error.graphQLErrors };

    if (!isArrayEmpty(errors)) {
      errors.forEach((graphQLError) => {
        if (graphQLError.field && graphQLError.reason === 'Regex') {
          validationErrors.push(graphQLError);
        } else {
          requestErrors.push(graphQLError);
        }
      });
    }
  }

  return [validationErrors, requestErrors];
};

export const useGraphQLValidationErrors = (error: ApolloError | undefined) => {
  const formApi = useRef<FormApi<SubmittedValues> | null>(null);

  const setFormApi = useCallback(
    (formApiValue: FormApi<SubmittedValues>) => {
      formApi.current = formApiValue;
    },
    [formApi],
  );

  return useMemo(() => {
    const [validationErrors, requestErrors] = getErrorsFromGraphQLRequest(error, formApi);

    return { setFormApi, validationErrors, requestErrors, formApi };
  }, [error, setFormApi]);
};
