import { createContext } from 'react';

type VisitorInformationContextType = {
  id: string;
  sessionId: string;
  userAgent: string;
  referrer: string;
  browserSize: {
    height: number;
    width: number;
  };
  isActiveDev: boolean;
  browserLanguage: string;
} | null;

const VisitorInformationContext = createContext<VisitorInformationContextType>(null);

export default VisitorInformationContext;
