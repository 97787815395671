import useRoutes from 'hooks/useRoutes';
import { useLocation } from 'react-router';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { isHotDealsType } from 'components/Listing/utils/utils';
import { PageViewTypes } from 'pages/CategoryView/provider/Category.provider';

const usePageView = () => {
  const routes = useRoutes();
  const { search, pathname } = useLocation<{
    redirect: Redirect;
  }>();

  const isCategoryPage = !!useRouteMatch({
    path: [routes.categoryPage, routes.category.root],
    exact: true,
  });
  const isSearchPage = !!useRouteMatch(routes.searchPage.root);
  const isImageSearchPage = !!useRouteMatch(routes.imageSearchPage.root);
  const isMultiProductView = new URLSearchParams(search).get('view') === 'mplp';

  return useMemo(() => {
    if (isMultiProductView) {
      return PageViewTypes.MPLP;
    }
    if (isImageSearchPage) {
      return PageViewTypes.ImageSearch;
    }
    if (isCategoryPage) {
      return PageViewTypes.Category;
    }
    if (isSearchPage) {
      return PageViewTypes.Search;
    }
    if (isHotDealsType(pathname)) {
      return PageViewTypes.Hotdeals;
    }
    return PageViewTypes.Others;
  }, [isCategoryPage, isImageSearchPage, isMultiProductView, isSearchPage, pathname]);
};

export default usePageView;
