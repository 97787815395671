import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { StatsigProvider, Statsig, StatsigContext, StatsigOptions } from 'statsig-react';
import { useTracking } from 'utils/tracking/hooks';
import useConsent from 'hooks/useConsent';
import useLanguage from 'hooks/useLanguage';
import STATSIG_CLIENT_SDK_KEY_MAP from 'config/statsig-key';
import useMandantConfig from 'hooks/useMandantConfig';
import { getExperimentsByLanguage } from 'utils/experiments';

interface ExperimentStatsigProviderProps {
  children: ReactNode;
  visitorId?: string;
}

const hasValidEnforcement = (override: any) =>
  override.expiry && Date.now() < override.expiry + 300000;

const searchAndAddInExperimentObjects = (
  param: any,
  experimentObject: any,
  experimentLocalConfig: any,
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in experimentObject) {
    if (typeof experimentObject[key] === 'object' && experimentObject[key] !== null) {
      if (experimentObject[key].key === param[0]) {
        experimentLocalConfig.push({
          key: experimentObject[key].id,
          variant: param[1],
        });
      }
    }
  }
};

const checkValidParamsForExperiment = (
  params: URLSearchParams,
  experimentsFromMandant: any,
  experimentsFromConfigFile: any,
) => {
  const experimentLocalConfig: any[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const param of params) {
    searchAndAddInExperimentObjects(param, experimentsFromMandant, experimentLocalConfig);
    searchAndAddInExperimentObjects(param, experimentsFromConfigFile, experimentLocalConfig);
  }
  return experimentLocalConfig;
};
export const StatsigConsent = createContext(false);

const DUMMY_KEY = 'client-dummy';
const STATSIG_VENDOR = 'cstm_statsig';

const STATSIG_LOCAL_STORAGE_KEY = 'statsig_enforce';

const ExperimentStatsigProvider = ({ children, visitorId }: ExperimentStatsigProviderProps) => {
  const language = useLanguage();
  const statsigConsent = useConsent(STATSIG_VENDOR);
  const { experiments = '{}' } = useMandantConfig();
  const experimentsFromMandant = useMemo(() => JSON.parse(experiments), [experiments]);
  const experimentsFromConfigFile = getExperimentsByLanguage(language);
  const tracking = useTracking(
    {
      user: visitorId,
    },

    'StatsigProvider',
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const key: string = STATSIG_CLIENT_SDK_KEY_MAP?.[language?.languageCode] ?? DUMMY_KEY;
  const user = {
    userID: visitorId,
  };

  const options: StatsigOptions = {
    initCompletionCallback: () => {
      tracking('createdId', {
        type: 'StatsigProvider',
        context: [],
      });
    },
    localMode: key === DUMMY_KEY,
  };

  if (!CONFIG.IS_SSR) {
    const urlParams = window.location.search;
    if (urlParams !== '') {
      const currentParams = new URLSearchParams(urlParams);
      const experimentLocalConfig = checkValidParamsForExperiment(
        currentParams,
        experimentsFromMandant,
        experimentsFromConfigFile,
      );
      if (experimentLocalConfig.length > 0) {
        localStorage.setItem(
          STATSIG_LOCAL_STORAGE_KEY,
          JSON.stringify({
            value: experimentLocalConfig,
            expiry: Date.now(),
          }),
        );
      }
    }

    const override = JSON.parse(localStorage.getItem(STATSIG_LOCAL_STORAGE_KEY) ?? '{}');
    if (hasValidEnforcement(override)) {
      (window as any).STATSIG_LOCAL_ACTIVATED = true;
      options.localMode = true;
    }
  }

  return (
    <StatsigConsent.Provider value={statsigConsent}>
      <StatsigProvider sdkKey={key} waitForInitialization={false} user={user} options={options}>
        <StatsigOverrides>{children}</StatsigOverrides>
      </StatsigProvider>
    </StatsigConsent.Provider>
  );
};

const StatsigOverrides = ({ children }: any) => {
  const statsigContext = useContext(StatsigContext);
  useEffect(() => {
    if (!statsigContext.initialized || CONFIG.IS_SSR) {
      return;
    }
    const override = JSON.parse(localStorage.getItem(STATSIG_LOCAL_STORAGE_KEY) ?? '{}');
    if (hasValidEnforcement(override)) {
      const setup = override.value;
      // eslint-disable-next-line no-restricted-syntax
      for (const param of setup) {
        Statsig.overrideConfig(param.key, { variant: param.variant });
      }
    } else {
      if (override?.value) {
        const setup = override.value;
        // eslint-disable-next-line no-restricted-syntax
        for (const param of setup) {
          Statsig.removeConfigOverride(param.key);
        }
      }
      localStorage.removeItem(STATSIG_LOCAL_STORAGE_KEY);
    }
  }, [statsigContext.initialized]);

  return children;
};
export default ExperimentStatsigProvider;
