import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { useQuery } from '@apollo/client';
import { GET_CARTS_QUERY } from 'pages/Checkout/Checkout.query';

/**
 * removing product from the cart in local.
 * we use this function after deleting a product from reservation or order cart or when restoring the product before being deleted.
 */
export function removeProductFromLocal(cartId, entryNumber) {
  const products = local.getItem('cart');
  if (products) {
    const result = products.filter(
      (item) => item.cartId !== cartId && item.entryNumber !== entryNumber,
    );
    local.setItem('cart', [...result]);
  }
}

/**
 * add deleted product to cart in local.
 * we use this function when deleting a product from reservation or order cart.
 */
export function addProductToLocal(cartId, entryNumber, allProducts) {
  const product = { cartId, entryNumber, allProducts };
  const products = local.getItem('cart');
  if (!products) {
    local.setItem('cart', [product]);
  } else {
    const result = products.find(
      (item) => item.cartId === cartId && item.entryNumber === entryNumber,
    );
    if (!result) {
      local.setItem('cart', [...products, product]);
    }
  }
}

/**
 * Get a cart entry by index
 * @param cart the cart
 * @param entryIndex index of the entry
 * @returns the entry or undefined
 */
export const getEntryFromCart = (cart, entryIndex) => {
  const { entries = [] } = cart || {};
  return entries.find((entry) => entry.entryNumber === entryIndex);
};

/**
 * Extract the cart from a graphql response - failsafe
 * @param cartsResponse the graphql response
 * @returns the cart or an empty object
 */
export function getCurrentCart(cartsResponse) {
  const cartData = cartsResponse?.data ? cartsResponse.data : cartsResponse;

  const { getCarts } = cartData || {};
  const { carts = [] } = getCarts || {};
  return carts[0] || {};
}

/**
 * Properly mutates the cache with an updated carts object
 * @param query the cached graphql query (GET_CARTS_QUERY)
 * @param updatedCart the updated cart object
 */
export function updateCurrentCart(query = {}, updatedCart = {}) {
  const currentCart = getCurrentCart(query);
  const newCart = { ...currentCart, ...updatedCart }; // merge both carts into a new object
  return {
    ...query,
    getCarts: { __typename: 'getCarts', ...(query?.getCarts || {}), carts: [newCart] },
  }; // set new cart object in carts array
}

const updateRemovedVouchers = (filteredVoucherMessages) => {
  const removedVouchers = [];
  let { vouchersRemoved } = local.getItem('checkout') || {};

  filteredVoucherMessages.forEach((message) => {
    const voucherCode = message.arguments[0].value;
    const voucherDescription = message.arguments[1].value;

    if (!isArrayEmpty(vouchersRemoved)) {
      vouchersRemoved = vouchersRemoved.filter((voucher) => voucher['0'] !== voucherCode);
    }
    removedVouchers.push({
      0: voucherCode,
      1: voucherDescription,
    });
  });

  if (isArrayEmpty(vouchersRemoved)) {
    vouchersRemoved = removedVouchers;
  } else {
    vouchersRemoved = vouchersRemoved.concat(removedVouchers);
  }
  local.setItem('checkout', {
    vouchersRemoved,
  });
};

/**
 * Generate query for order carts and evaluates the response. This is currently necessary in order being able to store
 * information about "vanished" products in cart on a central place, as this information can be retrieved only once.
 * @param {Object} options - the options for the GraphQL query
 *   @param {boolean} options.evaluateMessages - true to evaluate cart messages. Carts queried for finalized orders don't need to be evaluated
 *   @param {string} options.name - the name of the props value (defaults to 'orderCarts')
 *   @param {Object} options.variables - object for query variables
 *     @param {ORDER | RESERVATION} options.variables.type - the type for which carts to query for ()
 * @param completedCallback - executes arbitrary follow-up actions on received data
 * @returns the wrapped component
 */

const fetchData = (completedCallback, data, evaluateMessages = true) => {
  if (evaluateMessages) {
    const { messages } = getCurrentCart(data);
    if (!isArrayEmpty(messages)) {
      const filteredVoucherMessages = messages.filter((m) => m.message === 'cart.voucher.removed');
      if (!isArrayEmpty(filteredVoucherMessages)) {
        updateRemovedVouchers(filteredVoucherMessages);
      }
    }
  }
  if (completedCallback) {
    completedCallback(data);
  }
};

export function useCartsQuery(options, completedCallback) {
  const { evaluateMessages, variables, ...customOptions } = options;

  return useQuery(GET_CARTS_QUERY, {
    variables: {
      type: 'ORDER',
      ...variables,
    },
    onCompleted: (data) => {
      fetchData(completedCallback, data, evaluateMessages);
    },
    ...customOptions,
  });
}

export const updateWithCurrentCartResponse = (store, response) => {
  const query = store.readQuery({ query: GET_CARTS_QUERY });
  const data = updateCurrentCart(query, response);
  const result = store.writeQuery({ data, query: GET_CARTS_QUERY });

  fetchData(null, data, true);

  return result;
};

export const updateCartCache = updateWithCurrentCartResponse;

/**
 * Concatenate unique products in the reservation cart,
 * independent of the subsidiary they have been reserved in.
 *
 * This avoids redundant display of articles.
 */
export const uniqueProducts = (cart) => {
  const { entries = [] } = cart;
  const uniqueCart = entries.reduce((obj, entry) => {
    const {
      product: { code },
      quantity = 0,
      selectedQuantity = 0,
      totalPrice = {},
    } = entry;

    const { value: totalPriceValue = 0 } = totalPrice;
    const inserted = obj[code];

    // If no other article containing the same product code has been found yet,
    // add it to the object and return.
    if (!Object.prototype.hasOwnProperty.call(obj, code) || !inserted) {
      return {
        ...obj,
        [code]: { ...entry, quantity, selectedQuantity, totalPrice },
      };
    }

    // Since the totalPrice is a nested object itself, assign it to an own variable.
    const updatedTotalPrice = {
      ...inserted.totalPrice,
      ...(Object.prototype.hasOwnProperty.call(inserted.totalPrice, 'value')
        ? { value: inserted.totalPrice.value + totalPriceValue } // only add the totalPrice value, if there is already price data available
        : { value: totalPriceValue }),
    };

    // Concatenate the previously added, identical product, which has been reserved in a different subsidiary,
    // with the current product data, e.g.
    // - update the quantity
    // - set the previously calculated total price
    const duplicated = {
      ...inserted,
      quantity: inserted.quantity + quantity,
      selectedQuantity: inserted.selectedQuantity + selectedQuantity,
      totalPrice: updatedTotalPrice,
    };

    // Spread in the newly updated product data at the previous code property:
    return {
      ...obj,
      [code]: duplicated,
    };
  }, {});

  return Object.values(uniqueCart);
};

/**
 * Filters external products and internal products
 *
 * The internal products should be first in entries
 */

export const getSortedCartEntries = (entries) => {
  const internalProducts = [];
  const externalProducts = [];

  entries?.map((entry) => {
    if (entry.product.priceData?.offerAttributes) {
      return externalProducts.push(entry);
    }
    return internalProducts.push(entry);
  });

  return { internalEntries: internalProducts, externalEntries: externalProducts };
};

export const checkDeliveryModesInMoment = (deliveryModes) => {
  const cartWithSameDeliveryModes = deliveryModes.every(
    (delivery) => delivery === deliveryModes[0],
  );
  return cartWithSameDeliveryModes ? deliveryModes[0] : 'Multiple';
};

export const getDeliveryModes = (entries) =>
  entries?.map((entry) => entry.selectedDeliveryMode || entry.deliveryMode.name) ?? [];

/**
 * Check cart entries for offerAttributes which is only available in external products
 *
 * Util method to know whenever a cart contains external and internal products in it
 *
 * @param entries array of cart entries
 * @returns a boolean indicating if this is a Mixed cart or not
 */
export const isMixedCart = (entries) =>
  entries?.some((entry) => entry.product.priceData.offerAttributes !== null) &&
  entries.some((entry) => entry.product.priceData.offerAttributes === null);

/**
 * Check cart entries for offerAttributes which is only available in external products
 *
 * Util method to know whenever a cart contains only external products in it
 *
 * @param entries array of cart entries
 * @returns a boolean indicating if this is an external products only cart or not
 */
export const isExternalProductsOnlyCart = (entries) =>
  entries?.every((entry) => entry.product?.priceData?.offerAttributes !== null);

/**
 * set a ping for an eventListener to update the cart among different tabs
 */
export const setUpdateCartPing = () =>
  window.localStorage.setItem('updateCart', Math.random().toString());
