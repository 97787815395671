import {
  DispatchProps,
  ImageSearchCropData,
  ImageSearchProps,
  ADD_ERROR,
  ADD_PRODUCTS,
  HIDE_MODAL,
  LOADING,
  REMOVE_ERROR,
  SET_INSPIRATIONAL_CROP,
  SHOW_MODAL,
  UPLOAD_IMAGE,
} from 'pages/ImageSearch/ImageSearch.types';

export const SEARCH_BAR_IMAGE_INTERACTION_PURPOSE = 'search.bar.image.icon.interactions';
export const SEARCH_BAR_IMAGE_FLYOUT_INTERACTION_PURPOSE = 'search.bar.image.flyout.interactions';
export const SEARCH_BAR_RESULTS_INTERACTION_PURPOSE = 'search.bar.image.result.interactions';

export const INITIAL_CROP: ImageSearchCropData = {
  x: 13,
  y: 13,
  unit: '%',
  width: 74,
  height: 74,
};

export const search = {
  imgSrc: null,
  file: null,
  products: null,
};

export const initialState = {
  isInitialCrop: true,
  searches: [],
  isLoading: false,
  showModal: false,
  error: null,
  inspirationalCrop: null,
};

export function reducer(state: ImageSearchProps, action: DispatchProps) {
  switch (action.type) {
    case ADD_PRODUCTS:
      return {
        ...state,
        isLoading: false,
        showModal: false,
        error: null,
        searches: state.searches.map((entry, index) => {
          if (index === parseInt(action.payload.searchParam, 10)) {
            return { ...entry, ...action.payload.search };
          }
          return { ...entry };
        }),
        isInitialCrop: action.payload.isInitialCrop,
      };
    case ADD_ERROR:
      return {
        ...state,
        isLoading: false,
        showModal: false,
        error: action.payload,
      };
    case REMOVE_ERROR:
      return {
        ...state,
        error: null,
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        isLoading: true,
        showModal: false,
        searches: [
          ...state.searches,
          {
            ...search,
            ...action.payload,
            crop: (action.payload.isInspirationalImage && state.inspirationalCrop) || INITIAL_CROP,
          },
        ],
        isInitialCrop: true,
        error: null,
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case SET_INSPIRATIONAL_CROP:
      return {
        ...state,
        error: null,
        inspirationalCrop: action.payload,
      };
    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      throw new Error();
  }
}
